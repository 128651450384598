import React from 'react'
import AddBtn from '../AddBtn/AddBtn'
import { useApi } from '../Store/ApiContext'

export default function TopBarPage({titlePage, textAddBtn}) {
    const {baseApi, openFormComponent, closeFormComponent, validationData, responseApi, getData, removeData} = useApi()

  return (
    <>
    <div className='topBarPage'>
        <div className="d-flex justify-content-between align-items-center">
            <h2 className="fw-bolder fs-2">
                {titlePage}
            </h2>
            <div>
                {textAddBtn && <AddBtn text={textAddBtn} openFormComponent={openFormComponent}/>}
            </div>
        </div>
    </div>
    </>
  )
}
