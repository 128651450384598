import React, { useEffect, useState } from 'react'
import GoogleAnalyticsApp from '../GoogleAnalyticsApi/GoogleAnalyticsApi'
import { GoogleOAuthProvider } from '@react-oauth/google'
import ClarityData from '../ClarityData/ClarityData'
import AnalyticsTile from '../AnalyticsTile/AnalyticsTile'
import AnalyticsBarsCard from '../AnalyticsBarsCard/AnalyticsBarsCard'
import HeaderDashboard from '../HeaderDashboard/HeaderDashboard'
import { useApi } from '../Store/ApiContext'
import AnalyticsBarsCardPopularPage from '../AnalyticsBarsCardPopularPage/AnalyticsBarsCardPopularPage'

// export default function Dashboard() {
//   const {getData} = useApi();

//   const [analyticsData, setAnalyticsData] = useState({})

//   async function getAnalyticsData() {
//     const {message, data} = await getData("analytics");
//     console.log(JSON.parse(data.OS));
//     if (message === "success") {
//       setAnalyticsData(data)
//     }
//   }
//     useEffect(() => {
//       getAnalyticsData()
//     }, [])
    

//   return (
//     <>
//     <section className='dashboard mt-5 scrollbar-style'>
//       { analyticsData?.id ?
//       <>
//         <HeaderDashboard data={analyticsData} />
//         <div className='container-fluid'>
//           <div className='row'>
//             <div className="col-xl-4">
//             <AnalyticsTile titleTile={"وقت التفاعل"} infoTile={analyticsData.engagementTime + "%"} infoSubtitle={'من الجلسات بها وقت التفاعل'} />
//               <AnalyticsTile titleTile={"التمرير الزائد"} infoTile={analyticsData.excessiveScroll + "%"} infoSubtitle={'من الجلسات بها التمرير الزائد'} />
//               <AnalyticsBarsCardPopularPage data={JSON.parse(analyticsData.popularPages)} titleBarsCard={"الصفحات الأكثر زيارة"} />
//               <AnalyticsBarsCard data={JSON.parse(analyticsData.referrers)} titleBarsCard={"الإحالة"}/>
//             </div>
//             <div className="col-xl-4">
//             <AnalyticsBarsCard data={JSON.parse(analyticsData.browser)} titleBarsCard={"المتصفح"}/>
//               <AnalyticsBarsCard data={JSON.parse(analyticsData.device)} titleBarsCard={"الجهاز"}/>
//               <AnalyticsTile titleTile={'عدد النقرات الغير فعالة'} infoTile={analyticsData.deadClickCount + "%"} infoSubtitle={'من الجلسات بها نقرات الغير فعالة'} />
//               <AnalyticsTile titleTile={'عدد النقرات العابسة'} infoTile={analyticsData.rageClickCount + "%"} infoSubtitle={'من الجلسات بها النقرات العابسة'} />
//               <AnalyticsTile titleTile={'عدد النقرات العودة السريعة'} infoTile={analyticsData.quickBackClick + "%"} infoSubtitle={'من الجلسات بها النقرات العودة السريعة'} />
//             </div>
//             <div className="col-xl-4">
//               <AnalyticsBarsCard data={JSON.parse(analyticsData.OS)} titleBarsCard={"نظام التشغيل"}/>
//               <AnalyticsBarsCard data={JSON.parse(analyticsData.country)} titleBarsCard={"الدولة"}/>
//               <AnalyticsTile titleTile={"عدد أخطاء السكريبت"} infoTile={analyticsData.scriptErrorCount + "%"} infoSubtitle={'من الجلسات بها أخطاء السكريبت'} />
//               <AnalyticsTile titleTile={"عدد النقرات الخاطئة"} infoTile={analyticsData.errorClickCount + "%"} infoSubtitle={'من الجلسات بها النقرات الخاطئة'} />
//             </div>
//           </div>
//         </div>
//       </> : <></>
//       }

//     </section>
//     </>
//   )
// }

export default function Dashboard() {
  const {getData} = useApi();

  const [analyticsData, setAnalyticsData] = useState({})

  async function getAnalyticsData() {
    const {message, data} = await getData("analytics");
    console.log(JSON.parse(data.OS));
    if (message === "success") {
      setAnalyticsData(data)
    }
  }
    useEffect(() => {
      getAnalyticsData()
    }, [])
    

  return (
    <>
    <section className='dashboard mt-5 scrollbar-style'>
      { analyticsData?.id ?
      <>
        <div className='container-fluid'>
          <HeaderDashboard data={analyticsData} />
          <div className='row'>
            <div className="col-xl-4">
              <AnalyticsTile titleTile={"وقت التفاعل"} infoTile={analyticsData.engagementTime + "SEC"} infoSubtitle={'من الجلسات بها وقت التفاعل'} />
              <AnalyticsTile titleTile={"التمرير الزائد"} infoTile={analyticsData.excessiveScroll + "%"} infoSubtitle={'من الجلسات بها التمرير الزائد'} />
              <AnalyticsBarsCardPopularPage data={JSON.parse(analyticsData.popularPages)} titleBarsCard={"الصفحات الأكثر زيارة"} />
              <AnalyticsBarsCard data={JSON.parse(analyticsData.referrers)} titleBarsCard={"الإحالة"}/>
            </div>
            <div className="col-xl-4">
            <AnalyticsBarsCard data={JSON.parse(analyticsData.browser)} titleBarsCard={"المتصفح"}/>
              <AnalyticsBarsCard data={JSON.parse(analyticsData.device)} titleBarsCard={"الجهاز"}/>
              <AnalyticsTile titleTile={'عدد النقرات الغير فعالة'} infoTile={analyticsData.deadClickCount + "%"} infoSubtitle={'من الجلسات بها نقرات الغير فعالة'} />
              <AnalyticsTile titleTile={'عدد النقرات العابسة'} infoTile={analyticsData.rageClickCount + "%"} infoSubtitle={'من الجلسات بها النقرات العابسة'} />
              <AnalyticsTile titleTile={'عدد النقرات العودة السريعة'} infoTile={analyticsData.quickBackClick + "%"} infoSubtitle={'من الجلسات بها النقرات العودة السريعة'} />
            </div>
            <div className="col-xl-4">
              <AnalyticsBarsCard data={JSON.parse(analyticsData.OS)} titleBarsCard={"نظام التشغيل"}/>
              <AnalyticsBarsCard data={JSON.parse(analyticsData.country)} titleBarsCard={"الدولة"}/>
              <AnalyticsTile titleTile={"عدد أخطاء السكريبت"} infoTile={analyticsData.scriptErrorCount + "%"} infoSubtitle={'من الجلسات بها أخطاء السكريبت'} />
              <AnalyticsTile titleTile={"عدد النقرات الخاطئة"} infoTile={analyticsData.errorClickCount + "%"} infoSubtitle={'من الجلسات بها النقرات الخاطئة'} />
            </div>
          </div>
        </div>
      </> : <></>
      }

    </section>
    </>
  )
}