import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from 'rc-pagination/lib/Pagination'
import React, { useEffect, useState } from 'react'
import SeasonTableList from '../SeasonTableList/SeasonTableList'
import AddBtn from '../AddBtn/AddBtn'
import {useApi} from '../Store/ApiContext';
import SeasonForm from '../SeasonForm/SeasonForm'
import TopBarPage from '../TopBarPage/TopBarPage'
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom'
import SeasonGridItem from '../SeasonGridItem/SeasonGridItem'

export default function SeasonPage() {

    const {category, media} = useParams();

    const [data, setData] = useState([]);
    const [oldData, setOldData] = useState({})
    const [isEdit, setIsEdit] = useState(true)
    const [seasonId, setSeasonId] = useState(null)

    const {baseApi, openFormComponent, closeFormComponent, validationData, responseApi, getData, removeData} = useApi()

    async function handleApiResponse(data) {
        console.log(data);
        console.log(oldData);
        const result = await responseApi(oldData?.seasonName ? `season/${oldData?.id}` : `season/${media}`, oldData?.id ? "put" : "post", data)
        console.log(result);
        if (result?.message === "success") {
            closeFormComponent();
            fetchData()
            return 1
        } else {
            toast('لقد حدث خطأ', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                rtl : true
                });
            console.log(result);
        }
    }

    async function changeSeasonFeature(category, id, feature) {
        const {message} = await responseApi(`season/feature/${category}/${id}`, "patch", {feature : !feature ? true : false} )
        if (message === "success") {
            toast(message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                rtl : true
            });
            fetchData();
        }else {
            toast(message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                rtl : true
                });
        }
    }
    async function sendData(data) {
        const apiResponse = await handleApiResponse(data);
        console.log(apiResponse);
        if (apiResponse) {
            return 1
        }
    }
    async function fetchData() {
        const {data} = await getData(`season/${media}`)
        console.log(data);
        setData(data)
    };

    useEffect(() => {
        console.log(media);
        fetchData();
    }, [setData, getData])

  return (
    <>
    <section>
        <TopBarPage titlePage={"المواسم"} textAddBtn={"اضافة موسم"}/>
        <div className="container-fluid text-start mt-5">
            {/* <div className='header-season-list d-flex justify-content-between align-items-center mb-5'>
                <div>
                    <h2 className="fw-bolder title-page">
                        العنصر
                    </h2>
                </div>
                <div>
                    <AddBtn text={"اضافة عنصر"} openFormComponent={openFormComponent}/>
                </div>
            </div> */}
            {/* <SeasonTableList data={data} category={category} media={media} setOldData={setOldData} fetchData={fetchData} /> */}
            <SeasonGridItem  data={data} setOldData={setOldData} fetchData={fetchData} setIsEdit={setIsEdit} setSeasonId={setSeasonId} changeSeasonFeature={changeSeasonFeature}/>
        </div>
    </section>
    <SeasonForm sendData={sendData} setOldData={setOldData} oldData={oldData} isEdit={isEdit} setIsEdit={setIsEdit} seasonId={seasonId} fetchData={fetchData}/>
    
    {/* <Form.Control as="textarea" rows={3} /> */}
    {/* <textarea name="test" id="e" cols="30" rows="10"></textarea> */}
    </>
  )
}
