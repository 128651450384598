import React from 'react'

export default function HeaderDashboard({data}) {
  return (
    <>
    <div className='header-dashboard mb-5'>
        <div className="container-fluid">
            <div className="row g-0">
                <div className="col-lg-3 col-sm-6 mb-lg-0 mb-3">
                    <div className='header-part'>
                        <h6>الزيارات</h6>
                        <hr className='line-header-dashboard-part-1' />
                        <div className='d-flex justify-content-between'>
                            <div>
                                <p>إجمالي عدد الزيارات</p>
                                <h5>{data.totalSessions}</h5>
                            </div>
                            <div>
                                <p>عدد الزيارات الآليه</p>
                                <h5 className='text-end'>{data.totalBotSessions}</h5>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
                <div className="col-lg-3 col-sm-6 mb-lg-0 mb-3">
                    <div className='header-part line-header-parts-1'>
                        <h6>نسبة الصفحات لكل زيارة</h6>
                        <hr className='line-header-dashboard-part-2' />
                        <div>
                            <h5>{data.pagesPerSession}%</h5>
                            <p>متوسط</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 mb-lg-0 mb-3">
                    <div className='header-part line-header-parts-2'>
                        <h6>عمق التمرير</h6>
                        <hr className='line-header-dashboard-part-3' />
                        <div>
                            <h5>{data.scrollDepth}%</h5>
                            <p>متوسط</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 mb-lg-0 mb-3">
                    <div className='header-part line-header-parts-1'>
                        <h6>عدد المستخدمين</h6>
                        <hr className='line-header-dashboard-part-4' />
                        <div>
                            <h5>{data.distinctUsers}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
