import Pagination from "rc-pagination";
import React from 'react'

export default function PaginationTable({data, numberPage, setNumberPage, pagination, totalItem}) {


    return (
        <>
        {data && pagination?.limit && <div className="d-flex justify-content-between align-items-center footer-table mt-4" dir="ltr">
            <Pagination className="ant-pagination" locale={"ar-ly"} defaultCurrent={1} current={numberPage} total={data.length} pageSize={totalItem} onChange={(current)=> {setNumberPage(current)}}/>
            <div className="number-row">
                {/* <p>عناصر: {data.length / totalItem < 10 ? data.length : numberPage * totalItem } / {data.length}</p> */}
                <p>عناصر: {pagination.limit + pagination.skip >= data.length ? data.length : pagination.limit + pagination.skip } / {data.length}</p>
            </div>
        </div>}
        </>
    )
}
