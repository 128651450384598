import React from 'react'

export default function AnalyticsTile({titleTile, infoTile, infoSubtitle}) {
    // Dead Click Count (عدد النقرات الغير فعالة):  "sessionsWithMetricPercentage": %
    // Rage Click Count (عدد النقرات العابسة):  "sessionsWithMetricPercentage": %
    // Quick Back Click (عدد النقرات العودة  السريعة ):  "sessionsWithMetricPercentage": %
    // Script Error Count (عدد أخطاء السكريبت):  "sessionsWithMetricPercentage": %
    // Error Click Count (عدد النقرات الخاطئة):  "sessionsWithMetricPercentage": % 
    // Scroll Depth (عمق التمرير): "averagescrollDepth" %
    // Excessive Scroll (التمرير الزائد): "sessionsWithMetricPercentage" %
    // "Total Sessions": "إجمالي عدد الزيارات", 
    // "Total Bot Sessions": "عدد الزيارات الآليه",
    // "Distinct Users": "عدد المستخدمين",
    // "Pages Per Session": نسبة الصفحات لكل زيارة %
    // Engagement Time (وقت التفاعل):  ---
    // Browser (المتصفح):
    // Device (الجهاز):
    // OS (نظام التشغيل):
    // Country (الدولة): 
    // Referrers (الإحالة):
    // Popular Pages (الصفحات الأكثر زيارة):

    const events = {

    }
    
  return (
    <>
    <div className='tile-card'>
        <div className='header-tile-card'>
            <h6>{titleTile}</h6>
        </div>
        <hr className='line-header-tile-card' />    
        <div className='body-tile-card'>
            <div className='d-flex align-items-end'>
                <h3 className='info-body-tile'>{infoTile}</h3>
                <p className='info-subtitle-body-tile'>{infoSubtitle}</p>
            </div>
        </div>
    </div>
    {/* <div className='tile-card'>
        <div className='header-tile-card'>
            <h6>عدد النقرات الغير فعالة</h6>
            
        </div>
        <hr className='line-header-tile-card' />    
        <div className='body-tile-card'>
            <div className='d-flex align-items-end'>
                <h3 className='percentage-body-tile'>20%</h3>
                <p className='small-explain-body-tile'>من الجلسات بها نقرات ميتة</p>
            </div>
        </div>
    </div> */}
    </>
  )
}
