import logo from './logo.svg';
import './App.css';
import GridItem from './components/GridItem/GridItem';
import TableList from './components/SeasonTableList/SeasonTableList';
import InputData from './components/InputData/InputData';
import SideBar from './components/sideBar/SideBar';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//Start clearCache
import packageJson from "../package.json";
import { getBuildDate } from './utils/utils';
import withClearCache from './ClearCache';
import axios from 'axios';
import { useApi } from './components/Store/ApiContext';
//End clearCache

const ClearCacheComponent = withClearCache(App);  
export default function MainApp() {
  return <ClearCacheComponent />;
}


export function App() {  
  
  return (
  <>
  <div className=''>
    <SideBar/>
  </div>
  </>
  );
}