import React, { useEffect, useState } from 'react'
import { ProgressBar } from 'react-bootstrap'

export default function AnalyticsBarsCard({data,titleBarsCard}) {

    const [totalSessionsCount, setTotalSessionsCount] = useState(0)

    useEffect(() => {
        console.log(data);
        if (data?.length) {
            let myTotalSessionsCount = 0;
            data.forEach(ele => {
                myTotalSessionsCount += parseInt(ele.sessionsCount)
            });
            console.log(myTotalSessionsCount);
            setTotalSessionsCount(myTotalSessionsCount)
        }
    }, [])
    
  return (
    <>
    <div className='bars-card'>
        <div className="header-bars-card">
            <h6 className='title-header-bars-card'>{titleBarsCard}</h6>
        </div>
        <hr className='line-header-bars-card'/>
        <div className="body-bars-card">
            {totalSessionsCount && data && data?.map((ele, i) => <>
                <div className='item-body-card' key={i}>
                    <p className='subtitle-body-bar-card text-end'>{ele.name}</p>
                    <div className='d-flex justify-content-center'>
                        <p className='subtitle-info-card'>{ele.sessionsCount}</p>
                        <div className='bar-progress my-auto'>
                            <ProgressBar>
                                <ProgressBar className='bar-body-bars-card-1' now={(ele.sessionsCount / totalSessionsCount) * 100 } label={`${(ele.sessionsCount / totalSessionsCount) * 100 }%`} />
                            </ProgressBar>
                        </div>
                    </div>
                </div>
            </>)

            }
            {/* <div className='item-body-card'>
                <p className='subtitle-body-bar-card text-end'>https://d3nh6k0be8b6t9.cloudfront.net</p>
                <div className='d-flex justify-content-center'>
                    <p className='subtitle-info-card'>5</p>
                    <div className='bar-progress my-auto'>
                        <ProgressBar>
                            <ProgressBar className='bar-body-bars-card-1' now={50} label={`${50}%`} />
                        </ProgressBar>
                    </div>
                </div>
            </div> */}
            {/* <div className='item-body-card'>
                <p className='subtitle-body-bar-card text-end'>https://d3nh6k0be8b6t9.cloudfront.net</p>
                <div className='d-flex justify-content-center'>
                    <p className='subtitle-info-card'>5</p>
                    <div className='bar-progress my-auto'>
                        <ProgressBar>
                            <ProgressBar className='bar-body-bars-card-1' now={50} label={`${50}%`} />
                        </ProgressBar>
                    </div>
                </div>
            </div> */}
        </div>
    </div>
    </>
  )
}
