    import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "jquery/dist/jquery.js";
import "./css/style.css";
import { createBrowserRouter, Navigate, Outlet, RouterProvider, createHashRouter } from "react-router-dom";
import 'animate.css';
import SideBar from './components/sideBar/SideBar';
import GridItem from './components/GridItem/GridItem';
import TableList from './components/SeasonTableList/SeasonTableList';
import NotFound from './components/NotFound/NotFound';
import CategoryPage from './components/CategoryPage/CategoryPage';
import MediaPage from './components/MediaPage/MediaPage';
import EpisodePage from './components/EpisodePage/EpisodePage';
  import ApiProvider, { useApi } from './components/Store/ApiContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // react-toastify style file
import 'react-date-range/dist/styles.css'; // react-date-range main style file
import 'react-date-range/dist/theme/default.css'; // react-date-range theme css file
import TopBar from './components/TopBar/TopBar';
import SeasonPage from './components/SeasonPage/SeasonPage';
import Login from './components/Login/Login';
import ArticlePage from './components/ArticlePage/ArticlePage';
import FormArticle from './components/FormArticle/FormArticle';
import Settings from './components/Settings/Settings';
import Dashboard from './components/Dashboard/Dashboard';

const ProtectedRoute = ({children, acceptPermission}) => {
  const {decodedToken} = useApi();
  const token = localStorage.getItem("token")
  if (token) {
    const { permissions, role } = decodedToken(token); 
    if (role && permissions) {
      if (permissions.includes(acceptPermission)) {
          return children;
      }else if (permissions.includes("management-blog")) {
        return <Navigate to={"/article"} />;
      } else {
        toast('ليس مسموح لك بالوصول لهذه الصفحة', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            rtl : true
            })
        return <Navigate to={"/not-found"} />;
      }
    } else {
      return <Navigate to="/login"/>;
    }
  } else {
    return <Navigate to="/login"/>;
  }
  
}

const ProtectedLogin = ({children}) => {
  let token = localStorage.getItem("token");
  console.log(token,"login protect");
  if (!token) {
    return children;
  } else {
    return <Navigate to="/"/>
  }
}

const router = createBrowserRouter([

  {
    path: "/",
    element: <><ProtectedRoute acceptPermission={"management-media"}><div className='d-flex'><App/><div className='main scrollbar-style'><TopBar/><Dashboard/></div></div></ProtectedRoute></>,
  },
  {
    path: "/category",
    element: <><ProtectedRoute acceptPermission={"management-media"}><div className='d-flex'><App/><div className='main scrollbar-style'><TopBar/><CategoryPage/></div></div></ProtectedRoute></>,
  },
  {
    path: "/:category",
    element: <><ProtectedRoute acceptPermission={"management-media"}><div className='d-flex'><App/><div className='main scrollbar-style'><TopBar/><MediaPage/></div></div></ProtectedRoute></>,
  },
  {
    path: "/:category/:media",
    element: <><ProtectedRoute acceptPermission={"management-media"}><div className='d-flex'><App/><div className='main scrollbar-style'><TopBar/><SeasonPage/></div></div></ProtectedRoute></>,
  },
  {
    path: "/:category/:media/:mediaSeason",
    element: <><ProtectedRoute acceptPermission={"management-media"}><div className='d-flex'><App/><div className='main scrollbar-style'><TopBar/><EpisodePage/></div></div></ProtectedRoute></>,
  },
  {
    path: "/article",
    element: <><ProtectedRoute acceptPermission={"management-blog"}><div className='d-flex'><App/><div className='main scrollbar-style'><TopBar/><ArticlePage/></div></div></ProtectedRoute></>,
  },
  {
    path: "/article/:ArticleAction",
    element: <><ProtectedRoute acceptPermission={"management-blog"}><div className='d-flex'><App/><div className='main scrollbar-style'><TopBar/><FormArticle /></div></div></ProtectedRoute></>,
  }, 
  {
    path: "/settings",
    element: <><ProtectedRoute acceptPermission={"management-settings"}><div className='d-flex'><App/><div className='main scrollbar-style'><TopBar/><Settings /></div></div></ProtectedRoute></>,
  }, 
  {
    path: "/login",
    element: <ProtectedLogin ><Login/></ProtectedLogin>,
  },
  {
    path : "/not-found",
    element : <NotFound/>
  },
  {
    path : "*",
    element : <NotFound/>
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
        <>
        <ApiProvider>
          <RouterProvider router={router} />
          <ToastContainer toastClassName="custom-toast"/>
        </ApiProvider>
        </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();