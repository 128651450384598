import React from 'react'
import { useApi } from '../Store/ApiContext';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function ProtectedRoles({acceptPermission, children}) {
    const {decodedToken} = useApi();

    const { permissions, role } = decodedToken();
    if (permissions.includes(acceptPermission)) {
        return children;
    } 
    // else {
    //     console.log("testt2");
    //     toast('ليس مسموح لك بالوصول لهذه الصفحة', {
    //         position: "top-right",
    //         autoClose: 3000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "light",
    //         rtl : true
    //         })
    //     return <Navigate to={"/not-found"} />;
        
    // }
}
