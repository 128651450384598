import { faDownload, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import $ from 'jquery';
import { Form } from 'react-bootstrap';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import { ar } from "date-fns/locale";
import { CustomProvider, DatePicker, DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite-rtl.min.css';
import arEG from 'rsuite/locales/ar_EG';
import Select from 'react-select';
import SaveBtn from '../SaveBtn/SaveBtn';
import { useApi } from '../Store/ApiContext';



export default function MediaForm1({saveFunc, sendData, setOldData, oldData}) {

  const {closeFormComponent, getOption } = useApi();

    // const [rowCount, setRowCount] = useState(1);
    const {
        register,
        handleSubmit,
        getValues,
        watch,
        setValue,
        reset,
        formState: { errors },
        control
      } = useForm()


      const onSubmit = async (data) => {
            const newData = handleData(data)
            console.log("data", data);
            console.log("newData", newData);
            const resData = await sendData(newData);
            console.log(resData);
            if (resData) {
                reset(
                    {
                      isMultiSeasons : false,
                      mediaName : ""
                    }
                )
            }
        // }
    }

      function handleData(data) {
        const newData = {
          mediaName : data.mediaName,
          isMultiSeasons : data.isMultiSeasons ? true : false,
        }
        return newData;
      }

      useEffect(() => {
        if (oldData?.mediaName) {
            console.log(oldData);
            setValue('mediaName', oldData.mediaName);
            setValue('isMultiSeasons', oldData.isMultiSeasons ? true : false );
        }
        
    }, [oldData, setValue])
     

  return (
    <>
      <div className="bg-overlay d-none" onClick={() => {closeFormComponent(); setOldData({});}}></div>
      <section className="form-component open-form">
        <div className="close-edit-btn" >
          <FontAwesomeIcon icon={faXmark} onClick={() => {closeFormComponent(); setOldData({});}} />
        </div>
        <div className="container text-start sections scrollbar-style">
          <form onSubmit={handleSubmit(onSubmit)}>
          <ul className="row g-0 form-section">
              <h5 className="title-form-section">تفاصيل العنصر</h5>
              <li className="col-12">
                <input
                  id="mediaName"
                  className="form-control"
                  placeholder="اسم العنصر"
                  {...register("mediaName", {
                    required: true,
                  })}
                />
                {errors.mediaName && errors.mediaName.type === "required" && (
                  <div className="text-danger">This is required</div>
                )}
              </li>
              <li className="form-check">
                <input
                  dir="rtl"
                  id="isMultiSeasons"
                  className="form-check-input"
                  type="checkbox"
                  {...register("isMultiSeasons", { 
                  })}
                />
                <label class="form-check-label"htmlFor="isMultiSeasons">
                    يوجد مواسم
                </label>
              </li>
            </ul>
            <SaveBtn text={"حفظ"} />
          </form>
        </div>
      </section>
    </>
  );
}
