import React from 'react'
import { useApi } from '../Store/ApiContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faFloppyDisk } from '@fortawesome/free-regular-svg-icons';

export default function SaveBtn({text, func}) {
  const {openFormComponent} = useApi();

  return (
    <div className='form-btn'>
      <button className='save-btn' onClick={func}>
        <FontAwesomeIcon className='me-2' icon={faFloppyDisk} />
        {text}
      </button>
    </div>
      
    
  )
}
