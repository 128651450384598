import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from 'rc-pagination/lib/Pagination'
import React, { useEffect, useState } from 'react'
import MediaTableList from '../MediaTableList/MediaTableList'
import AddBtn from '../AddBtn/AddBtn'
import {useApi} from '../Store/ApiContext';
import MediaForm1 from '../MediaForm1/MediaForm1'
import TopBarPage from '../TopBarPage/TopBarPage'
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom'
import MediaForm from '../MediaForm/MediaForm'

export default function MediaPage() {

    const {category} = useParams();

    const [data, setData] = useState([]);
    const [oldData, setOldData] = useState({})

    const {baseApi, openFormComponent, closeFormComponent, validationData, responseApi, getData, removeData} = useApi()

    async function handleApiResponse(data) {
        console.log(data);
        console.log(oldData);
        const result = await responseApi(oldData?.id ? `media/${oldData?.id}` : `media/${category}`, oldData?.id ? "put" : "post", data)
        console.log(result);
        if (result?.message === "success") {
            closeFormComponent();
            fetchData()
            return 1
        } else {
            toast('لقد حدث خطأ', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                rtl : true
            });
            console.log(result);
        }
    }

    async function sendData(data) {
        const apiResponse = await handleApiResponse(data)
        console.log(apiResponse);
        if (apiResponse) {
            return 1
        }
    }
    async function fetchData() {
        const {data} = await getData(`media/${category}`)
        console.log(data);
        setData(data)
    };

    useEffect(() => {
        fetchData();
    }, [setData, getData])

  return (
    <>
    <section>
        <TopBarPage titlePage={"العناصر"} textAddBtn={"اضافة عنصر"}/>
        <div className="media-list container-fluid text-start mt-5">
            {/* <div className='header-media-list d-flex justify-content-between align-items-center mb-5'>
                <div>
                    <h2 className="fw-bolder title-page">
                        العناصر
                    </h2>
                </div>
                <div>
                    <AddBtn text={"اضافة عنصر"} openFormComponent={openFormComponent}/>
                </div>
            </div> */}
            <MediaTableList data={data} category={category} setOldData={setOldData} fetchData={fetchData} />
        </div>
    </section>
    <MediaForm1 sendData={sendData} setOldData={setOldData} oldData={oldData}/>
    {!oldData.id ?  <MediaForm sendData={sendData} setOldData={setOldData} oldData={oldData} /> : <></>}
    
    {/* <Form.Control as="textarea" rows={3} /> */}
    {/* <textarea name="test" id="e" cols="30" rows="10"></textarea> */}
    </>
  )
}
