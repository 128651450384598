import { faFloppyDisk, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import $ from "jquery";
import { useApi } from '../Store/ApiContext';
import SaveBtn from '../SaveBtn/SaveBtn';

export default function CategoryForm({saveFunc, sendData, setOldData, oldData}) {

    const {closeFormComponent, } = useApi();
    
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
      } = useForm();


    //   function closeFormComponent() {
    //     $(".form-component").animate({left : "-400px"}, 500);
    //   }

    const onSubmit = async (data) => {
        const resData = await sendData(data);
        console.log(resData);
        if (resData) {
            reset(
                {
                    categoryName : "",
                    categoryRoute : ""
                }
            )
        }
    }

    function closeForm() {
        closeFormComponent()
        reset(
            {
                categoryName : "",
                categoryRoute : ""
            }
        )
        setOldData({})
    }
    
    useEffect(() => {
        if (oldData?.categoryName) {
            console.log(oldData);
            setValue('categoryName', oldData.categoryName);
            setValue('categoryRoute', oldData.categoryRoute);
        }
        
    }, [oldData, setValue])

    

  return (
    <>
    <div className='bg-overlay d-none' onClick={closeFormComponent}></div>
    <section className='form-component'>

        <div className="close-edit-btn" onClick={closeForm}>
            <FontAwesomeIcon icon={faXmark} />
        </div>
        <div className='container text-start '>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ul className='row g-0 form-section'>
                    <h5 className='title-form-section'>تفاصيل التصنيف</h5>
                    <li className='col-12'>
                        {/* <label htmlFor="name">اسم التصنيف</label> */}
                        <input
                            id="categoryName"
                            className='form-control'
                            placeholder="اسم التصنيف"
                            type='text'
                            {...register("categoryName", { required: true, pattern : /^([ا-ي]||\s){3,}$/})}
                        />
                        {errors.categoryName && errors.categoryName.type === "required" && (
                            <div className='text-danger'>This is required</div>
                        )}
                        {errors.categoryName && errors.categoryName.type === "pattern" && (
                            <div className='text-danger'>enter arabic only</div>
                        )}
                    </li>
                    <li className='col-12'>
                        {/* <label htmlFor="name">اسم الرابط</label> */}
                        <input
                            id="categoryRoute"
                            className='form-control'
                            placeholder="اسم الرابط"
                            type='text'
                            {...register("categoryRoute", { required: true, pattern: /^([a-z]||-){3,}$/ })}
                        />
                        {errors.categoryRoute && errors.categoryRoute?.type === "required" && (
                            <div className='text-danger'>This is required</div>
                        )}
                        {errors.categoryRoute && errors.categoryRoute?.type === "pattern" && (
                            <div className='text-danger'>enter english only</div>
                        )}
                    </li>
                </ul>
                <div className='form-btn'>
                    <SaveBtn text="تأكيد" />
                </div>
                
            </form>
        </div>
    </section>
    
    
    
    </>
  )
}
