import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment';
import { CustomProvider, DatePicker } from 'rsuite';
import 'rsuite/dist/rsuite-rtl.min.css';
import arEG from 'rsuite/locales/ar_EG';
import Select from 'react-select';
import SaveBtn from '../SaveBtn/SaveBtn';
import { useApi } from '../Store/ApiContext';



export default function MediaForm({sendData}) {

  const {closeFormComponent, getOption, UploadFile, deleteImage } = useApi();

    const [seasonBannerDeskUrl, setSeasonBannerDeskUrl] = useState("")
    const [seasonBannerMobUrl, setSeasonBannerMobUrl] = useState("")
    const [seasonPosterUrl, setSeasonPosterUrl] = useState("");

    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRtl, setIsRtl] = useState(true);

    const typeOptions = [
      { value: 'مغامرة', label: 'مغامرة', color: '#00B8D9', isFixed: true },
      { value: 'اثارة', label: 'اثارة', color: '#0052CC'},
      { value: 'خيال علمي', label: 'خيال علمي', color: '#5243AA' },
      { value: 'كوميديا', label: 'كوميديا', color: '#FF5630', isFixed: true },
      { value: 'جريمة', label: 'جريمة', color: '#FF8B00' },
      { value: 'دراما', label: 'دراما', color: '#FFC400' },
      { value: 'رعب', label: 'رعب', color: '#36B37E' },
      { value: 'وثائقي', label: 'وثائقي', color: '#00875A' },
      { value: 'موسيقي', label: 'موسيقي', color: '#253858' },
      { value: 'حرب', label: 'حرب', color: '#666666' },
  ];
  const tagOptions = [
    { value: 'انيمي', label: 'انيمي', color: '#00B8D9', isFixed: true },
    { value: 'تجسس', label: 'تجسس', color: '#0052CC'},
    { value: 'ملحمي', label: 'ملحمي', color: '#5243AA' },
    { value: 'احجيه', label: 'احجيه', color: '#FF5630', isFixed: true },
    { value: 'موسيقي', label: 'موسيقي', color: '#FF8B00' },
    { value: 'اخبار', label: 'اخبار', color: '#FFC400' },
    { value: 'ديني', label: 'ديني', color: '#36B37E' },
];
    const dot = (color = 'transparent') => ({
      borderRadius : 15,
    });
    const colourStyles = {
      control: (styles) => ({ ...styles, ...dot() }),
      input: (styles) => ({ ...styles, ...dot(),  }), // assuming dot() is defined somewhere
      placeholder: (styles) => ({ ...styles, ...dot("#ccc"), fontWeight : 600 }), // assuming dot() is defined somewhere
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color), borderColor : "#6c5dd3" }), // assuming dot() is defined somewhere
    };

    const {
        register,
        handleSubmit,
        getValues,
        watch,
        setValue,
        reset,
        formState: { errors },
        control
      } = useForm()


      const onSubmit = async (data) => {
        const newData = handleData(data)
        console.log("data", data);
        console.log("newData", newData);
        const resData = await sendData(newData);
        console.log(resData);
        if (resData) {
          resetInputs()
        }
    }

    function resetInputs() {
      reset(
        {
        seasonName : "",
        seasonDesc : "",
        seasonRelease : "",
        seasonType : [],
        seasonTags : [],
        seasonBannerDesk : "",
        seasonBannerMob : "",
        seasonPoster : "",
        seasonTrailer : "",
        seasonNumber : "",
        isMultiSeasons : false,
        mediaRoute : ""
        }
      )
      setSeasonBannerDeskUrl("")
      setSeasonBannerMobUrl("")
      setSeasonPosterUrl("")
    }

  function handleData(data) {
    const seasonTagsValues = []
    console.log(data);
    data.seasonTags.forEach(ele => {
      seasonTagsValues.push(ele.value)
    });
    const newData = {
      seasonName : data.seasonName,
      seasonDesc : data.seasonDesc,
      seasonRelease : moment(data.seasonRelease).format(),
      seasonType : data.seasonType.value,
      seasonTags : seasonTagsValues,
      seasonBannerDesk : seasonBannerDeskUrl,
      seasonBannerMob : seasonBannerMobUrl,
      seasonPoster : seasonPosterUrl,
      seasonTrailer : data.seasonTrailer,
      seasonNumber : data.isMultiSeasons ? data.seasonNumber : 1,
      isMultiSeasons : data.isMultiSeasons,
      mediaRoute : data.mediaRoute,
    }
    return newData;
  } 

  async function getImageFile(file, setState) {
    const imageUrl = await UploadFile(file);
    console.log(imageUrl);
    if (imageUrl) {
      setState(imageUrl);
    }
  }

  async function handleImageProcess(file, imageUrl, setImageUrl) {
    if (imageUrl) {
        const message = await deleteImage(imageUrl);
        if (message === "success") {
            getImageFile(file, setImageUrl);
        }
    } else {
        getImageFile(file, setImageUrl);
    }
  }

  useEffect(() => {
    const file = getValues("seasonBannerDesk")[0];
    if (file?.type?.startsWith("image")) {
        handleImageProcess(file, seasonBannerDeskUrl, setSeasonBannerDeskUrl)
    }
}, [watch("seasonBannerDesk")]);

  useEffect(() => {
    const file = getValues("seasonPoster")[0];
    if (file?.type?.startsWith("image")) {
        handleImageProcess(file, seasonPosterUrl, setSeasonPosterUrl)
    }
  }, [watch("seasonPoster")]);

  useEffect(() => {
    const file = getValues("seasonBannerMob")[0];
    if (file?.type?.startsWith("image")) {
        handleImageProcess(file, seasonBannerMobUrl, setSeasonBannerMobUrl)
    }
  }, [ watch("seasonBannerMob") ]);    

  return (
    <>
      <div className="bg-overlay d-none" onClick={() => {closeFormComponent(); resetInputs();}}></div>
      <section className="form-component">
        <div className="close-edit-btn">
          <FontAwesomeIcon icon={faXmark} onClick={() => {closeFormComponent(); resetInputs();}}/>
        </div>
        <div className="container text-start sections scrollbar-style">
          <form onSubmit={handleSubmit(onSubmit)}>
            <ul className="row g-0 form-section">
              <h5 className="title-form-section">تفاصيل العنصر</h5>
              <li className="col-12">
                <input
                  id="seasonName"
                  className="form-control"
                  placeholder="اسم العنصر"
                  {...register("seasonName", { required: true, maxLength: 30 })}
                />
                {errors.seasonName && errors.seasonName.type === "required" && (
                  <div className="text-danger">This is required</div>
                )}
                {errors.seasonName && errors.seasonName.type === "maxLength" && (
                  <div className="text-danger">Max length exceeded</div>
                )}
              </li>
              <li className="col-12">
                <input
                  id="mediaRoute"
                  className="form-control"
                  placeholder="اسم الرابط"
                  {...register("mediaRoute", {
                    required: true,
                    pattern: /^([a-z]||-){3,}$/,
                  })}
                />
                {errors.mediaRoute && errors.mediaRoute.type === "required" && (
                  <div className="text-danger">This is required</div>
                )}
                {errors.mediaRoute && errors.mediaRoute.type === "pattern" && (
                  <div className="text-danger">
                    enter english only
                  </div>
                )}
              </li>
              <li className="form-check">
                <input
                  dir="rtl"
                  id="isMultiSeasons"
                  className="form-check-input"
                  type="checkbox"
                  {...register("isMultiSeasons")}
                />
                <label class="form-check-label"htmlFor="isMultiSeasons">
                    يوجد مواسم
                </label>
              </li>
              {watch("isMultiSeasons") ? <li className="col-12">
                <input
                  dir="rtl"
                  id="seasonNumber"
                  className="form-control"
                  placeholder="رقم الموسم"
                  type="text"
                  {...register("seasonNumber", {
                    required: true,
                    maxLength: 30,
                  })}
                />
                {errors.seasonNumber &&
                  errors.seasonNumber.type === "required" && (
                    <div className="text-danger">This is required</div>
                  )}
              </li> : <></>}
              
              <li className="col-12">
                <input
                  dir="rtl"
                  id="seasonTrailer"
                  className="form-control"
                  placeholder="اعلان العنصر"
                  type="text"
                  {...register("seasonTrailer", {
                    required: true,
                    maxLength: 200,
                  })}
                />
                {errors.seasonTrailer &&
                  errors.seasonTrailer.type === "required" && (
                    <div className="text-danger">This is required</div>
                  )}
                {errors.seasonTrailer &&
                  errors.seasonTrailer.type === "maxLength" && (
                    <div className="text-danger">Max length exceeded</div>
                  )}
              </li>
              <li className="col-12">
                <CustomProvider locale={arEG}>
                  <Controller
                    name="seasonRelease"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        oneTap
                        placeholder="تاريخ الإصدار"
                        style={{ width: "100%" }}
                        {...field}
                      />
                    )}
                  />
                </CustomProvider>
              </li>
              <li className="col-12">
                <textarea
                  className="form-control"
                  name="seasonDesc"
                  id="seasonDesc"
                  rows="5"
                  placeholder="الوصف"
                  {...register("seasonDesc", { required: false, max: 500 })}
                ></textarea>
                {errors.seasonDesc && errors.seasonDesc.type === "required" && (
                  <div className="text-danger">This is required</div>
                )}
                {errors.seasonDesc && errors.seasonDesc.type === "maxLength" && (
                  <div className="text-danger">Max length exceeded</div>
                )}
              </li>
              <li>
                <Controller
                  name="seasonType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      className="basic-single"
                      classNamePrefix="نوع العنصر"
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="seasonType"
                      options={typeOptions}
                      placeholder="نوع العنصر"
                      styles={colourStyles}
                      {...field}
                    />
                  )}
                />
                {errors.seasonType && errors.seasonType.type === "required" && (
                  <div className="text-danger">This is required</div>
                )}
              </li>
              <li>
                <Controller
                  name="seasonTags"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      name="seasonTags"
                      options={tagOptions}
                      className="basic-multi-select"
                      classNamePrefix="نوع العنصر"
                      placeholder="وسوم العنصر"
                      styles={colourStyles}
                      {...field}
                    />
                  )}
                />
                {errors.seasonTags && errors.seasonTags.type === "required" && (
                  <div className="text-danger">This is required</div>
                )}
              </li>
            </ul>
            <ul className="form-section">
              <h5 className="title-form-section">صور العنصر</h5>
              <li className="">
                <div>
                  <h6 className="mb-2">صورة العنصر الرئيسية (بانر)</h6>
                </div>
                <div className="text-center">
                  <input
                    type="file"
                    name="seasonBannerDesk"
                    id="seasonBannerDesk"
                    className="inputFile"
                    {...register("seasonBannerDesk", { required: seasonBannerDeskUrl ? false : true })}
                  />
                  <label
                    className="my-2 cursor-pointer"
                    htmlFor="seasonBannerDesk"
                  >
                    {seasonBannerDeskUrl ?
                      <div className='preview-image'>
                        <img className='w-75' src={seasonBannerDeskUrl} alt="Banner Mobile" />
                        <span className="size-image">1440x810</span>
                      </div>  : (
                      <>
                        <div className="example-image">1440x810</div>
                      </>
                    )}{" "}
                  </label>
                </div>
                {errors.seasonBannerDesk &&
                  errors.seasonBannerDesk.type === "required" && (
                    <div className="text-danger">This is required</div>
                  )}
              </li>
              <li className="">
                <div>
                  <h6 className="mb-2">صورة العنصر في الموبيل</h6>
                </div>
                <div className="text-center">
                  <input
                    type="file"
                    name="seasonBannerMob"
                    id="seasonBannerMob"
                    className="inputFile"
                    {...register("seasonBannerMob", { required: seasonBannerMobUrl ? false : true })}
                  />
                  <label
                    className="my-2 cursor-pointer"
                    htmlFor="seasonBannerMob"
                  >
                    {seasonBannerMobUrl ?
                      <div className='preview-image'>
                        <img className='w-75' src={seasonBannerMobUrl} alt="Banner desktop" />
                        <span className="size-image">420x622</span>
                      </div> : (
                      <>
                        <div className="example-image">420x622</div>
                      </>
                    )}{" "}
                  </label>
                </div>
                {errors.seasonBannerMob &&
                  errors.seasonBannerMob.type === "required" && (
                    <div className="text-danger">This is required</div>
                  )}
              </li>
              <li className="">
                <div>
                  <h6 className="mb-2">صورة العنصر بالمحتوي</h6>
                </div>
                <div className="text-center">
                  <input
                    type="file"
                    name="seasonPoster"
                    id="seasonPoster"
                    className="inputFile"
                    {...register("seasonPoster", { required: seasonPosterUrl ? false : true })}
                  />
                  <label className="my-2 cursor-pointer" htmlFor="seasonPoster">
                  {seasonPosterUrl ?
                      <div className='preview-image'>
                        <img className='w-75' src={seasonPosterUrl} alt="poster with content" />
                        <span className="size-image">297x167</span>
                      </div> : (
                      <>
                        <div className="example-image">297x167</div>
                      </>
                    )}{" "}
                  </label>
                </div>
                {(errors.seasonPoster &&
                  errors.seasonPoster.type === "required") && (
                    <div className="text-danger">This is required</div>
                  )}
              </li>
            </ul>
            <SaveBtn text={"حفظ"} />
          </form>
        </div>
      </section>
    </>
  );
}
