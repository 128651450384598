import { faEllipsis, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import $ from 'jquery';
import { useApi } from "../Store/ApiContext";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

export default function GridItem({data, setOldData, fetchData}) {

    const {openFormComponent, getData, removeData} = useApi();


    function toggleDropdownSetting(e) {
        $(e.target).parents("button").next().toggle();
    }

  return (
    <>
        <div className="row g-4 pb-4 grid-box scrollbar-style">
            {data?.map((ele) => {
                return (
                <div className="col-xl-4 col-lg-6">
                    <div className="box">
                        <div className="header-box">
                            <div className="title-box">
                                <h3>{ele.categoryName}</h3>
                            </div>
                            {/* <div className="settings-box">
                                <button onClick={toggleDropdownSetting}>
                                    <FontAwesomeIcon icon={faEllipsis} />
                                </button>
                                <ul className="dropdown-settings">
                                    <li className="edit-btn mb-3"  onClick={(e) => {openFormComponent(e); setOldData(ele); }}>
                                        <FontAwesomeIcon className="me-2" icon={faPen} />
                                        <span>تعديل</span>
                                    </li>
                                    <li className="delete-btn" onClick={async () => {await removeData(`category/${ele.id}`); await fetchData();}}>
                                        <FontAwesomeIcon className="me-2" icon={faTrash} />
                                        <span>حذف</span>
                                    </li>
                                </ul>
                            </div> */}
                            <Dropdown className="settings-box">
                                <Dropdown.Toggle id="dropdown-basic">
                                    <FontAwesomeIcon icon={faEllipsis} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-settings">
                                    <Dropdown.Item onClick={(e) => {openFormComponent(e); setOldData(ele); }}>
                                        <FontAwesomeIcon className="icon-dropdown me-2" icon={faPen} />
                                        <span>تعديل</span></Dropdown.Item>
                                    <Dropdown.Item onClick={async () => {await removeData(`category/${ele.id}`); await fetchData();}}>
                                        <FontAwesomeIcon className="icon-dropdown me-2" icon={faTrash} />
                                        <span>حذف</span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        {/* <div className='img-box'>
                                <img className='w-100' src={imgBox1} alt="" />
                            </div> */}
                        <div className="route-box">
                            <h5>{ele.categoryRoute}</h5>
                        </div>
                        <div className="view-item-btn">
                            <Link to={`/${ele.categoryRoute}`}>
                                <button>عرض العناصر</button>
                            </Link>
                        </div>
                    </div>
                </div>
                )
            })}
        </div>
    </>
  );
}
