import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../Store/ApiContext';
import { useForm } from 'react-hook-form';
import TopBarPage from '../TopBarPage/TopBarPage';
import SaveBtn from '../SaveBtn/SaveBtn';
import { toast } from 'react-toastify';

export default function Settings() {
    const { closeFormComponent, getData, responseApi } = useApi();  
    const [settingsId, setSettingsId] = useState(0);

    const {
      register,
      handleSubmit,
      getValues,
      watch,
      setValue,
      reset,
      control,
      formState: { errors },
    } = useForm();
  
    const onSubmit = async (data) => {
      const newData = handleData(data);
      await sendData(newData);
    };
  
    function handleData(data) {
      const newData = {
        broadcastLink: data.broadcastLink,
      };
      return newData;
    }
  
    async function handleApiResponse(data) {
      console.log(data);
      const result = await responseApi(`settings`, "patch", data);
      console.log(result);
      if (result?.message === "success") {
        toast("تم حفظ بنجاح", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          rtl: true,
        });
        return 1;
      } else {
        toast("لقد حدث خطأ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          rtl: true,
        });
        console.log(result);
      }
    }
  
    async function sendData(data) {
      const apiResponse = await handleApiResponse(data);
      console.log(apiResponse);
      if (apiResponse) {
        return 1;
      }
    }
    function setInputValue(data) {
      if (data?.broadcastLink) {
        console.log(data);
        setValue("broadcastLink", data.broadcastLink);
      }
    }

    async function fetchData() {
        const {data} = await getData(`settings`)
        console.log(data);
        setInputValue(data[0])
        setSettingsId(data[0].id)
    };
  
    useEffect(() => {
      fetchData()
    }, [])
  
    return (
      <>
        <TopBarPage titlePage={"الإعدادات"} />
        <section className="form-page mt-5 pt-3">
          <div className="container-fluid scrollbar-style test-form text-start w-100">
            <form onSubmit={handleSubmit(onSubmit)}>
              <ul className="row gx-2 gy-0 form-section">
                <li className="col-12 mb-3 mt-1">
                  <label htmlFor="broadcastLink" className='mb-1'>رابط البث المباشر</label>
                  <input
                    id="broadcastLink"
                    className="form-control"
                    placeholder="رابط البث المباشر"
                    aria-label="default input example"
                    {...register("broadcastLink", {
                      required: true,
                    })}
                  />
                  {errors.broadcastLink &&
                    errors.broadcastLink.type === "required" && (
                      <div className="text-danger">This is required</div>
                    )}
                </li>
              </ul>
              <div className="form-page-btn">
                <SaveBtn text={"حفظ"} />
              </div>
            </form>
          </div>
        </section>
      </>
    );
}
