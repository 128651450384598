import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import $ from 'jquery';
import { useApi } from '../Store/ApiContext';
import moment from 'moment';
import SaveBtn from '../SaveBtn/SaveBtn';
import ReactPlayer from 'react-player/youtube'

export default function EpisodeForm({saveFunc, sendData, setOldData, oldData}) {

      const {closeFormComponent, getOption, UploadFile, deleteImage } = useApi();
      const [duration, setDuration] = useState(null);
      const [pip, setPip] = useState(null);
    const [episodeImageUrl, setEpisodeImageUrl] = useState("")


      const {
        register,
        handleSubmit,
        getValues,
        watch,
        setValue,
        reset,
        formState: { errors },
        control
    } = useForm();
  
  
    const onSubmit = async (data) => {
        if (episodeImageUrl) {
            const newData = handleData(data)
            console.log("data", data);
            console.log("newData", newData);
            const resData = await sendData(newData);
            console.log(resData);
            if (resData) {
                clearInputs()
            }
        }
    }

    function clearInputs(params) {
        reset(
            {
            episodeName : "",
            episodeDuration : "",
            episodeVideo : "",
            episodePoster : "",
            episodeNumber : "",
            episodeDesc : "",
            }
        )
        setEpisodeImageUrl("")
        setOldData({})
    }
  
      //   function closeFormComponent() {
      //     $(".form-component").animate({left : "-400px"}, 500);
      //   };
        // function getImageFile(e, setState) {
        //   console.log(e.target.files);
        //   setState(e.target.files[0])
        // }
        // function displayTest(fileName) {
        //   console.log(fileName);
        //   return fileName.length >= 35 ? "..." + fileName.slice(0,35) : fileName
        // }
  
        function handleData(data) {
          const newData = {
            episodeName : data.episodeName,
            episodeDesc : data.episodeDesc,
            episodeDuration : duration,
            episodeVideo : data.episodeVideo,
            episodeNumber : data.episodeNumber,
            episodePoster : episodeImageUrl,
          }
          return newData;
        }

    const handleDuration = (duration) => {
        console.log('Video duration:', duration);
        setDuration(duration);
        };
        
    const handlePip = (pip) => {
        console.log('Video pip:', pip);
        setPip(pip);
        };

    function setFormInputValue() {
        setValue('episodeName', oldData.episodeName);
        setValue('episodeDesc', oldData.episodeDesc);
        setValue('episodeDuration', oldData.episodeDuration);
        setValue('episodeVideo', oldData.episodeVideo);
        setValue('episodeNumber', oldData.episodeNumber);
        setValue('episodePoster', oldData.episodePoster);
        setEpisodeImageUrl(oldData.episodePoster);
    }



    async function getImageFile(file, setState) {
        const imageUrl = await UploadFile(file);
        if (imageUrl) {
            setState(imageUrl);
        }
    }

    // async function deleteImage(url) {
    //     // Split the URL by "/"
    //     const parts = url.split("/");

    //     // Get the last part of the URL
    //     const imageId = parts[parts.length - 1];
    //     console.log(imageId);
    //     const {message} = await responseApi(`server/${imageId}`,"delete");
    //     return message;
    // }

    async function handleImageProcess(file, episodeImageUrl, setEpisodeImageUrl) {
        if (episodeImageUrl) {
            const message = await deleteImage(episodeImageUrl);
            if (message === "success") {
                getImageFile(file, setEpisodeImageUrl);
            }
        } else {
            getImageFile(file, setEpisodeImageUrl);
        }
    }


    useEffect(() => {
        if (oldData?.episodeName) {
            setFormInputValue()
        }
    }, [oldData, setValue]);
    
    useEffect(() => {
        const file = getValues("episodePoster")[0];
        if (file?.type?.startsWith("image")) {
            handleImageProcess(file, episodeImageUrl, setEpisodeImageUrl)
        }
    }, [watch("episodePoster")]);
    

    return (
    <>
    <div className="bg-overlay d-none" onClick={(e) => {closeFormComponent(e); clearInputs();}}></div>
    <section className='form-component'>
        <div className="close-edit-btn" onClick={(e) => {closeFormComponent(e); clearInputs();}}>
            <FontAwesomeIcon icon={faXmark} />
        </div>
        <div className='container text-start sections scrollbar-style'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ul className='row g-0 form-section'>
                    <h5 className="title-form-section">معلومات الحلقة</h5>
                    <li className=''>
                        <input
                            id="episodeName"
                            className='form-control'
                            placeholder="اسم الحلقة" aria-label="default input example"
                            {...register("episodeName", { required: true, maxLength: 100 })}
                        />
                        {errors.episodeName && errors.episodeName.type === "required" && (
                            <div className='text-danger'>This is required</div>
                        )}
                        {errors.episodeName && errors.episodeName.type === "maxLength" && (
                            <div className='text-danger'>Max length exceeded</div>
                        )}
                    </li>
                    <li className=''>
                        <input
                        dir='rtl'
                            id="episodeNumber"
                            className='form-control'
                            placeholder="رقم الحلقة" aria-label="default input example"
                            type='text'
                            {...register("episodeNumber", { required: true, maxLength: 30 })}
                        />
                        {errors.episodeNumber && errors.episodeNumber.type === "required" && (
                            <div className='text-danger'>This is required</div>
                        )}
                    </li>
                    <li className=''>
                        <textarea
                            id="episodeDesc"
                            className='form-control'
                            rows={5}
                            placeholder="وصف الحلقة"
                            {...register("episodeDesc", { required: false, maxLength: 200 })}
                        />
                        {errors.episodeDesc && errors.episodeDesc.type === "required" && (
                            <div className='text-danger'>This is required</div>
                        )}
                        {errors.episodeDesc && errors.episodeDesc.type === "maxLength" && (
                            <div className='text-danger'>Max length exceeded</div>
                        )}
                    </li>
                    <li className=''>
                        <input
                        dir='rtl'
                            id="episodeVideo"
                            className='form-control'
                            placeholder="رابط الحلقة" aria-label="default input example"
                            type='text'
                            {...register("episodeVideo", { required: true})}
                        />
                        {errors.episodeVideo && errors.episodeVideo.type === "required" && (
                            <div className='text-danger'>This is required</div>
                        )}
                    </li>
                    <li className='duration-picker'>
                        <ReactPlayer url={watch("episodeVideo")} width={"100%"} height={watch("episodeVideo") ? 200 : 0} onDuration={handleDuration} />

                        {/* <input
                        dir='rtl'
                            id="episodeDuration"
                            className='form-control'
                            placeholder="مدة الحلقة بالدقايق" aria-label="default input example"
                            type='number'
                            min={1}
                            {...register("episodeDuration", { required: true, maxLength: 30 })}
                        />
                        {errors.episodeDuration && errors.episodeDuration.type === "required" && (
                            <div className='text-danger'>This is required</div>
                        )}
                        {errors.episodeDuration && errors.episodeDuration.type === "maxLength" && (
                            <div className='text-danger'>Max length exceeded</div>
                        )} */}
                    </li>
                    <li className="">
                        <div>
                        <h6 className="mb-2">صورة الحلقة</h6>
                        </div>
                        {/* <div className="example-image">297x167</div> */}
                        <div className="text-center">
                        <input
                            type="file"
                            name="episodePoster"
                            id="episodePoster"
                            accept='image/*'

                            className="inputFile"
                            // onChange={(e) => getImageFile(e, setSeasonPosterFile)}
                            {...register("episodePoster", { required: oldData.episodePoster ? false : true })}
                        />
                        <label className="my-2 cursor-pointer" htmlFor="episodePoster">
                            {episodeImageUrl ?
                            <>
                            <div className='preview-image'>
                                <img className='w-75' src={episodeImageUrl} alt="Banner Mobile" />
                                <span className="size-image">297x167</span>
                            </div>
                            </>
                             : (
                            <>
                                <div className="example-image">297x167</div>
                            </>
                            )}{" "}
                        </label>
                        </div>
                        {errors.episodePoster &&
                        errors.episodePoster.type === "required" && (
                            <div className="text-danger">This is required</div>
                        )}
                    </li>
                </ul>
                <SaveBtn text={"حفظ"} />
            </form>
        </div>
    </section>
    
    
    
    </>
  )
}
