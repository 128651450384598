import { faDownload, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import $ from 'jquery';
import { Form } from 'react-bootstrap';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import { ar } from "date-fns/locale";
import { CustomProvider, DatePicker, DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite-rtl.min.css';
import arEG from 'rsuite/locales/ar_EG';
import Select from 'react-select';
import SaveBtn from '../SaveBtn/SaveBtn';
import { useApi } from '../Store/ApiContext';
import { toast } from 'react-toastify';



export default function FormSeasonBroadcastTime({saveFunc, setOldData, oldData, isEdit, setIsEdit, seasonId, fetchData}) {

  const {closeFormComponent, getOption, responseApi } = useApi();

    const [rows, setRows] = useState([{ id: 1 }]);

    const [isClearable, setIsClearable] = useState(false);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRtl, setIsRtl] = useState(true);
    const dayOptions = [
        { value: '0', label: 'الأحد', color: '#00B8D9', isFixed: true },
        { value: '1', label: 'الأثنين', color: '#5243AA' },
        { value: '2', label: 'الثلاثاء', color: '#FF5630', isFixed: true },
        { value: '3', label: 'الاربعاء', color: '#FF8B00' },
        { value: '4', label: 'الخميس', color: '#FFC400' },
        { value: '5', label: 'الجمعة', color: '#36B37E' },
        { value: '6', label: 'السبت', color: '#00875A' },
    ];
    const dot = (color = 'transparent') => ({
            // alignItems: 'center',
            // display: 'flex',
            borderRadius : 15,
        
            // ':before': {
            //     backgroundColor: color,
            //     borderRadius: 20,
            //     content: '" "',
            //     display: 'block',
            //     marginRight: 8,
            //     height: 10,
            //     width: 10,
            // },
    });
    const colourStyles = {
      control: (styles) => ({ ...styles, ...dot() }),
    //   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //     const color = chroma(data.color);
    //     return {
    //       ...styles,
    //       backgroundColor: isDisabled
    //         ? undefined
    //         : isSelected
    //         ? data.color
    //         : isFocused
    //         ? color.alpha(0.1).css()
    //         : undefined,
    //       color: isDisabled
    //         ? "#ccc"
    //         : isSelected
    //         ? chroma.contrast(color, "white") > 2
    //           ? "white"
    //           : "black"
    //         : data.color,
    //       cursor: isDisabled ? "not-allowed" : "default",

    //       ":active": {
    //         ...styles[":active"],
    //         backgroundColor: !isDisabled
    //           ? isSelected
    //             ? data.color
    //             : color.alpha(0.3).css()
    //           : undefined,
    //       },
    //     };
    //   },
      input: (styles) => ({ ...styles, ...dot(),  }), // assuming dot() is defined somewhere
      placeholder: (styles) => ({ ...styles, ...dot("#ccc"), fontWeight : 600 }), // assuming dot() is defined somewhere
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color), borderColor : "#6c5dd3" }), // assuming dot() is defined somewhere
    };

    const [broadcast, setBroadcast] = useState([
        {
            startDate: moment().toDate(),
            endDate: moment().add(1, "day").toDate(),
            key: 'selection',
        }
    ]);

      const onSubmit = async (e) => {
        e.preventDefault()
        const newData = handleData()
        console.log("newData", newData);
        if (seasonId != null) {
          const resData = await handleApiResponse(newData);
          console.log(resData);
          // if (resData) {
            // resetInputs()
          // }
            // setBroadcast([
            //   {
            //       startDate: moment().toDate(),
            //       endDate: moment().add(1, "day").toDate(),
            //       key: 'selection',
            //   }
            //   ])
        }
      }
      async function handleApiResponse(data) {
        console.log(data);
        const result = await responseApi(`broadcastTime/${seasonId}`, "post", data)
        console.log(result);
        if (result?.message === "success") {
            closeFormComponent();
            setIsEdit(true)
            fetchData()
            return 1
        } else {
            toast('لقد حدث خطأ', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                rtl : true
                });
            console.log(result);
        }
    }

    //   async function sendData(data) {
    //     const apiResponse = await handleApiResponse(data)
    //     console.log(apiResponse);
    //     if (apiResponse) {
    //         return 1
    //     }
    // }

      const addNewRebroadcast = () => {
        // console.log({ id: rows[rows.length - 1].id + 1, data: '' });
        const newRow = { id: rows.length ?  rows[rows?.length - 1].id + 1 : 1, data: '' };
        setRows(prevRows => [...prevRows, newRow]);

      };
    
      const deleteRow = id => {
        setRows(prevRows => prevRows.filter(row => row.id !== id));
      };
    
      const handleDateChange = (id, newData) => {
        console.log(newData);
        setRows(prevRows =>
          prevRows.map(row => (row.id === id ? { ...row, startBroadcastTime: moment(newData[0]).format("HH:mm"), endBroadcastTime :  moment(newData[1]).format("HH:mm") } : row))
        );
        console.log(rows);
      };
      const handleDayChange = (id, days) => {
        const daysValue = []
        days.forEach((day) => {
          daysValue.push(day.value)
        })
        console.log(daysValue);
        setRows(prevRows =>
          prevRows.map(row => (row.id === id ? { ...row, broadcastDay : daysValue } : row))
        );
        console.log(rows);
      };

      function handleData(data) {
        let validBroadcastTime = false;
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].startBroadcastTime && rows[i].endBroadcastTime && rows[i].broadcastDay) {
            $(".broadcastTimeError").addClass("d-none");
            validBroadcastTime = true;
          } else {
            $(".broadcastTimeError").removeClass("d-none");
            validBroadcastTime = false;
          }

        }
        
        if (validBroadcastTime) {
            const newData = {
            startBroadcast :  moment(broadcast[0].startDate).format("YYYY-MM-DD"),
            endBroadcast :  moment(broadcast[0].endDate).format("YYYY-MM-DD"),
            broadcastTime : rows,
          }
          return newData;
        }
      }

      function resetInputs() {
      }

      useEffect(() => {
        console.log(seasonId);
      }, [seasonId])
      
     

  return (
    <>
        <div className="container text-start sections scrollbar-style">
            <form>
              <ul className="form-section-dateRange">
                <h5 className="title-form-section">مواعيد البث</h5>
                <li className="col-12 " dir="ltr">
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => setBroadcast([item.selection])}
                    moveRangeOnFirstSelection={false} 
                    ranges={broadcast}
                    locale={ar}
                    className="w-100"
                    startDatePlaceholder="من"
                    endDatePlaceholder="الي"
                  />
                </li>
              </ul>
              <ul className="form-section ">
                <h5 className="title-form-section">مواعيد بث العنصر</h5>

                <li className='broadcastTime scrollbar-style'>
                  <table className="table broadcastTime-table">
                    <thead className="table-dark">
                      <tr>
                        {/* <th>#</th> */}
                        <th>ميعاد البث</th>
                        <th>يوم البث</th>
                        <th>*</th>
                      </tr>
                    </thead>
                    <tbody>
                    {rows.map(row => (
                      <tr key={row.id}>
                        {/* <td className='fs-6 align-middle'>{row.id}</td> */}
                        <td>
                          <div>
                            <CustomProvider locale={arEG}>
                              <DateRangePicker
                                style={{ width: '100%' }}
                                format='HH:mm'
                                ranges={[]}
                                // showOneCalendar
                                preventOverflow={true}
                                // defaultCalendarValue={[new Date('2022-02-01 00:00:00'), new Date('2022-05-01 23:59:59')]}
                                onChange={(newData) => handleDateChange(row.id, newData)}
                              />
                            </CustomProvider>
                          </div>
                        </td>
                        <td>
                          <div style={{width : "130px"}}>
                              <Select
                                isMulti
                                closeMenuOnSelect={false}
                                className="basic-single"
                                classNamePrefix="يوم البث"
                                // defaultValue={options[0]}
                                isDisabled={isDisabled}
                                isLoading={isLoading}
                                isClearable={isClearable}
                                isRtl={isRtl}
                                isSearchable={isSearchable}
                                name="seasonBroadcast"
                                options={dayOptions}
                                placeholder="يوم البث"
                                styles={colourStyles}
                                onChange={(days) => handleDayChange(row.id, days)}
                              />
                          </div>

                        </td>
                        <td className={`delete-row-table ${row.length !== 1? "white-50" : ""}`} >
                          <button disabled={rows.length === 1 ? true : false} onClick={() => deleteRow(row.id)}><FontAwesomeIcon icon={faTrash} /></button>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                <div className='broadcastTimeError text-danger d-none'>برجاء ادخال البيانات</div>

                  { rows.length < 5 &&  <span
                    className="text-myPrimary cursor-pointer mb-2"
                    onClick={addNewRebroadcast}
                  >
                    <span className='fs-5'>+</span> اضافة ميعاد
                  </span>}
                 
                </li>
                    </ul>
              <SaveBtn text={"حفظ"} func={onSubmit} />
            </form>
        </div>
      {/* </section> */}
    </>
  );
}
