import { faEllipsis, faPen, faPodcast, faStar, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import $ from 'jquery';
import { useApi } from "../Store/ApiContext";
import { Link, useParams } from "react-router-dom";
import { Alert, Dropdown } from "react-bootstrap";
import { icon } from "@fortawesome/fontawesome-svg-core";

export default function SeasonGridItem({data, setOldData, fetchData, setIsEdit, setSeasonId, changeSeasonFeature}) {

    const {openFormComponent, getData, responseApi, removeData} = useApi();
    const {category} = useParams()


    function toggleDropdownSetting(e) {
        $(e.target).parents("button").next().toggle();
    }




  return (
    <>
        <div className="row g-4 pb-4 grid-box scrollbar-style">
            {data?.map((ele, i) => {
                return (
                <div className="col-xl-4 col-lg-6" key={i}>
                    <div className="box">
                        <div className="header-box">
                            <div className="title-box d-flex align-items-center">
                                <h3>{ele.seasonName}</h3>
                                {ele.feature ? <FontAwesomeIcon icon={faStar} style={{color: "#FFD43B", width : "30px"}} className="feature-star" onClick={() => changeSeasonFeature(ele.category, ele.id, ele.feature)} /> : <svg className="feature-star" onClick={() => changeSeasonFeature(ele.category, ele.id, ele.feature)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"/></svg>}
                                
                            </div>
                            {/* <div className="settings-box">
                                <button onClick={toggleDropdownSetting}>
                                    <FontAwesomeIcon icon={faEllipsis} />
                                </button>
                                <ul className="dropdown-settings">
                                    <li className="edit-btn mb-3"  onClick={() => {setIsEdit(false); openFormComponent(); setSeasonId(ele.id); }}>
                                        <FontAwesomeIcon className="me-2" icon={faPodcast} />
                                        <span>مواعيد</span>
                                    </li>
                                    <li className="edit-btn mb-3"  onClick={() => {openFormComponent(); setOldData(ele); }}>
                                        <FontAwesomeIcon className="me-2" icon={faPen} />
                                        <span>تعديل</span>
                                    </li>
                                    <li className="delete-btn" onClick={async () => {await removeData(`season/${ele.id}`); await fetchData();}}>
                                        <FontAwesomeIcon className="me-2" icon={faTrash} />
                                        <span>حذف</span>
                                    </li>
                                </ul>
                            </div> */}
                            <Dropdown className="settings-box">
                                <Dropdown.Toggle id="dropdown-basic">
                                    <FontAwesomeIcon icon={faEllipsis} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-settings">
                                    <Dropdown.Item onClick={() => {setIsEdit(false); openFormComponent(); setSeasonId(ele.id); }}>
                                        <FontAwesomeIcon className="me-2" icon={faPodcast} />
                                        <span>مواعيد</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item  onClick={() => {openFormComponent(); setOldData(ele); }}>
                                        <FontAwesomeIcon className="me-2" icon={faPen} />
                                        <span>تعديل</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={async () => {await removeData(`season/${ele.id}`); await fetchData();}}>
                                        <FontAwesomeIcon className="me-2" icon={faTrash} />
                                        <span>حذف</span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div>
                            <h5><span>الرابط: </span>{ele.mediaRoute}</h5>
                        </div>
                        <div className='img-box'>
                                {/* <img className='w-100' src={imgBox1} alt="" /> */}
                                
                            </div>
                        <div className="route-box">
                            <h4><span>موسم: </span>{ele.seasonNumber}</h4>
                        </div>
                        <div>
                        {ele.isYoutubeList || ele.isNoEpisodeList ? 
                            <div className="view-item-btn-disable">
                                <button>{ele.isYoutubeList ? "يوتيوب" : "بدون حلقات"}</button>
                            </div>
                            : <div className="view-item-btn"> 
                                <Link to={`${ele.id}`}>
                                    <button>عرض الحلقات</button>
                                </Link> 
                            </div>
                        }
                        </div>
                        {!ele.calenderBroadcast ? <Alert variant={"warning"} className="alert mt-2">
                            ادخل مواعيد البث
                        </Alert> : <></>}
                    </div>
                </div>
                )
            })}
        </div>
    </>
  );
}
