import React from 'react'
import loginPic from "../../img/pictures/login picture.png";
import logo from "../../img/logos/LTV-logo_Approved04-1-1536x1411.png";
import SaveBtn from '../SaveBtn/SaveBtn';
import { useApi } from '../Store/ApiContext';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

export default function Login() {

    
    const { responseApi, setToken } = useApi();
    const navigate = useNavigate()
    
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        const resData = await handleApiResponse(data);
        console.log(resData);
        if (resData) {
            reset(
                {
                    email : "",
                    password : ""
                }
            )
        }
    }

    async function handleApiResponse(data) {
        console.log(data);
        const result = await responseApi("auth/signin", "post", data)
        console.log(result);
        if (result?.message === "success") {
            localStorage.setItem("token", result.token)
            setToken(result.token)
            navigate("/");
        } else {
            toast(result.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                rtl : true
            });
            console.log(result);
        }
    }
    return (
        <>
        <div className='login'>
                <div className='login-form'>
                    <div className='mb-5'>
                        <h3 className='login-title'>مرحبا بكم في لوحة التحكم</h3>
                        <h3 className='login-title'>تسجيل الدخول للمتابعة.</h3>
                    </div>
                    {/* <p>Don't have an account? <a href="#">Create a new account.</a></p> */}
                    <form onSubmit={handleSubmit(onSubmit)}> 
                        <div class="mb-2">
                            {/* <labelhtmlFor="email">Email:</label> */}
                            <input className='form-control' 
                                type="text" 
                                name="email" 
                                placeholder="البريد الالكتروني"
                                {...register("email", { required: true, pattern : /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ })}
                            />
                            {errors.email && errors.email.type === "required" && (
                            <div className='text-danger'>This is required</div>
                            )}
                            {errors.email && errors.email.type === "pattern" && (
                                <div className='text-danger'>wrong email</div>
                            )}
                        </div>
                        <div class="mb-2">
                            {/* <labelhtmlFor="password">Password:</label> */}
                            <input className='form-control' 
                                type="password" 
                                name="password" 
                                placeholder="رقم السر" 
                                {...register("password", { required: true, pattern : /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/ })}
                            />
                            {errors.password && errors.password.type === "required" && (
                            <div className='text-danger'>This is required</div>
                        )}
                        {errors.password && errors.password.type === "pattern" && (
                            <div className='text-danger'>Minimum eight characters, at least one letter and one number</div>
                        )}
                        </div>
                            <button className='login-btn' type="submit">تسجيل الدخول</button>
                    </form>
                </div>
                <div className='login-img'>
                    <div className='overlay-login-pic'>
                        <img className='' src={logo} alt="login" />
                    </div>
                    <img className='h-100 w-100' src={loginPic} alt="login" />
                </div>
        </div>
        </>
    )
}
