import React, { useEffect, useState } from 'react'
import profileAvatar from '../../img/avatar/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.avif';
import { useApi } from '../Store/ApiContext';

export default function TopBar() {

  const {decodedToken} = useApi();
  const [userName, setUserName] = useState("")

  useEffect(() => {
    const { userName } = decodedToken();
    setUserName(userName.split(" ")[0])
}, [])

  return (
    <>
    <div className='topBar'>
        <div className='d-flex justify-content-between align-items-center'>
            <div>
                <h4>مرحباً، <span>{userName}</span></h4>
            </div>
            <div className=''>
              <img className='profileAvatar' src={profileAvatar} alt="" />
            </div>
        </div>
    </div>
    </>
  )
}
