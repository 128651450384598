import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import TopBarPage from '../TopBarPage/TopBarPage';
import EpisodeForm from '../EpisodeForm/EpisodeForm';
import { useApi } from '../Store/ApiContext';
import ArticleTableList from '../ArticleTableList/ArticleTableList';
import FormArticle from '../FormArticle/FormArticle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Navigate, useNavigate } from 'react-router-dom';

export default function ArticlePage() {
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [oldData, setOldData] = useState({})

    const {baseApi, openFormComponent, closeFormComponent, validationData, responseApi, getData, removeData} = useApi()

    async function handleApiResponse(data) {
        console.log(data);
        console.log(oldData);
        const result = await responseApi(oldData?.id ? `article/${oldData?.id}` : `article`, oldData?.id ? "put" : "post", data)
        console.log(result);
        if (result?.message === "success") {
            closeFormComponent();
            fetchData()
            return 1
        } else {
            toast('لقد حدث خطأ', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                rtl : true
                });
            console.log(result);
        }
    }

    async function sendData(data) {
        const apiResponse = await handleApiResponse(data)
        console.log(apiResponse);
        if (apiResponse) {
            return 1
        }
    }
    async function fetchData() {
        const {data} = await getData(`article`)
        console.log(data);
        setData(data)
    };

    useEffect(() => {
        fetchData();
    }, [setData, getData])

  return (
    <>
       <section>
        <div className='topBarPage'>
            <div className="d-flex justify-content-between align-items-center">
                <h2 className="fw-bolder">
                مقالات
                </h2>
                <div>
                <button className='add-btn' onClick={() => navigate("/article/add")}>
                    <FontAwesomeIcon className='plus-icon' icon={faPlus} />
                    اضافة مقاله            
                </button>
                </div>
            </div>
        </div>
        <div className="media-list container-fluid text-start mt-5">
            <ArticleTableList data={data} setOldData={setOldData} fetchData={fetchData} />
        </div>
    </section>
    {/* <EpisodeForm sendData={sendData} setOldData={setOldData} oldData={oldData} /> */}
    </>
  )
}
