import { faCircleInfo, faInfo, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import $ from 'jquery';
import { Link } from "react-router-dom";
import { useApi } from "../Store/ApiContext";
import PaginationTable from "../PaginationTable/PaginationTable";

export default function MediaTableList({data, category, setOldData, fetchData}) {

    const { getData, removeData} = useApi();

    const [numberPage, setNumberPage] = useState(1);
    const [totalItem, setTotalItem] = useState(10);
    const [pagination, setPagination] = useState({
        limit : 0,
        skip : 10
    })

    function paginate(page, size) {

        if (!page || page <= 0) {
            page = 1
        }
    
        if (!size || size <= 0) {
            size = 2
        }
        console.log(size, 1);
        const skip = (page - 1) * size
        console.log({limit: parseInt(size), skip});
        return {limit: parseInt(size), skip}
    }

    useEffect(() => {
        if (data?.length && numberPage) {
            setPagination(paginate(numberPage,10))
        }
    }, [data, numberPage])
    

    function openFormComponent() {
        $(".open-form").animate({left : "0"}, 400);
        $(".bg-overlay").removeClass("d-none");
      }

  return (
    <>
    <div className="table-list">
        <ul className="responsive-table">
            <li className="table-header">
                {/* <div className="col col-1">رقم العنصر</div> */}
                <div className="col col-1">اسم العنصر</div>
                <div className="col col-2">اسم الرابط</div>
                <div className="col col-5">الإعدادات</div>
            </li>
            {data && data.slice(pagination.skip, pagination.skip + pagination.limit).map((ele, i) => {
                return (
                        <li className={`table-row ${i % 2 === 0  ? "table-row-1" : "table-row-2"}`} key={i}>
                            {/* <div className="col col-1" data-label="رقم العنصر">
                            {ele.id}
                            </div> */}
                            <div className="col col-1" data-label="اسم العنصر">
                                {ele.mediaName}
                            </div>
                            <div className="col col-2" data-label="اسم الرابط">
                                {ele.mediaRoute}
                            </div>
                            <div className="col col-5" data-label="الإعدادات">
                                <ul className="settings-list">
                                    <span className="edit-btn" onClick={(e) => { openFormComponent(); setOldData(ele);}}>
                                        <FontAwesomeIcon className="me-3" icon={faPen} />
                                    </span>
                                    <span className="delete-btn"  onClick={async () => {await removeData(`media/${ele.id}`); await fetchData();}}>
                                        <FontAwesomeIcon className="me-3" icon={faTrash} />
                                    </span>
                                    <Link to={`/${category}/${ele.mediaRoute}`}>
                                        <span className="more-btn">
                                            <FontAwesomeIcon icon={faCircleInfo} />
                                        </span>
                                    </Link>

                                </ul>
                            </div>
                        </li>
                )
            })}
        </ul>
        <PaginationTable data={data} numberPage={numberPage} setNumberPage={setNumberPage} pagination={pagination} totalItem={totalItem}/>
    </div>
    </>
  );
}
