import { faAnglesLeft, faAnglesRight, faArrowRightFromBracket, faEarthAmericas, faHouse, faLayerGroup, faSliders } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import ltvLLogo from '../../img/logos/LTV-logo_Approved04-1-1536x1411.png';
import { Link, useNavigate } from 'react-router-dom';
import { useApi } from '../Store/ApiContext';
import ProtectedRoles from '../ProtectedRoles/ProtectedRoles';
import introLtv from '../../img/gif/logo-ltv-unscreen.gif';

export default function SideBar() {
    const navigate = useNavigate();
    const {decodedToken} = useApi();

    const [expandSideBar, setExpandSideBar] = useState(true);
    const [userInfo, setUserInfo] = useState({})

    function toggleExpandSidebar(e) {
        // $(".sidebar").toggleClass("expand");
        // $(".expand").toggle(); 
        if (expandSideBar) {
            // $(".sidebar").css({width: "220px"});
            $(".sidebar").animate({width: "220px"}, 300, () => {
                $(".expand").fadeIn();
                $(".expand-list").css("display", "inline-block");

                setExpandSideBar(!expandSideBar);
                // $(".icon-sidebar").css({marginBottom : "0px"})
                $(".main").animate({width: "calc(100vw - 230px)"}, 300)

            });
            // $(".expand").css({display : "inline-block"});
        }else {
            // $(".sidebar").css({width: "fit-content"});
            $(".sidebar").animate({width: "68px"}, 300, () => {
                $(".expand").fadeOut();
                $(".expand-list").css("display", "none");
                setExpandSideBar(!expandSideBar);
                // $(".icon-sidebar").css({marginBottom : "20px"})
                $(".main").animate({width: "calc(100vw - 68px)"}, 300);

            });
        }
        // setExpandSideBar(!expandSideBar);
    }
    function logout() {
        localStorage.clear();
        navigate("/login")
    }

    useEffect(() => {
        const { userName, role } = decodedToken();
        setUserInfo({userName, role})
    }, [])
    

  return (
    <>
    <div className='sidebar'>
        <div className='header-sidebar'>
                <div className='d-flex justify-content-between align-items-center'>
                    <Link  to="/">
                        <div className='title-company'>
                            <img className='logo-company expand' style={{display: "none"}} src={ltvLLogo} alt="" />
                            <h1 className='fs-3 fw-semibold expand' style={{display: "none"}}>LTV</h1>
                        </div>
                    </Link>
                    <div className='expand-sidebar cursor-pointer' onClick={toggleExpandSidebar}>
                        
                        {expandSideBar ? <FontAwesomeIcon icon={faAnglesLeft} /> : <FontAwesomeIcon icon={faAnglesRight}/>}
                    </div>
                </div>
            
        </div>
        <ul className="body-sidebar">
            
            <li className='list-sidebar'>
                <Link  to="/">
                    <div className='d-flex justify-content-start align-items-center '>
                    <FontAwesomeIcon className='icon-sidebar' icon={faHouse} />
                        <h2 className='title-list-sidebar expand-list' style={{ display : "none"}} >الرئيسية</h2>
                    </div>
                </Link>
            </li>
            <ProtectedRoles acceptPermission={"management-media"}>
                <li className='list-sidebar'>
                    <Link  to="/category">
                        <div className='d-flex justify-content-start align-items-center'>
                            <FontAwesomeIcon className='icon-sidebar' icon={faLayerGroup} />
                            <h2 className='title-list-sidebar expand-list' style={{ display : "none"}}>التصنيفات</h2>
                        </div>
                    </Link>
                </li>
            </ProtectedRoles>
            <ProtectedRoles acceptPermission={"management-blog"}>
                <li className='list-sidebar'>
                    <Link  to="/article">
                        <div className='d-flex justify-content-start align-items-center'>
                            <FontAwesomeIcon className='icon-sidebar' icon={faEarthAmericas} />
                            <h2 className='title-list-sidebar expand-list' style={{ display : "none"}}>الأخبار</h2>
                        </div>
                    </Link>
                </li>
            </ProtectedRoles>
            <ProtectedRoles acceptPermission={"management-settings"}>
                <li className='list-sidebar'>
                    <Link  to="/settings">
                        <div className='d-flex justify-content-start align-items-center'>
                            <FontAwesomeIcon className='icon-sidebar' icon={faSliders} />
                            <h2 className='title-list-sidebar expand-list' style={{ display : "none"}}>الإعدادات</h2>
                        </div>
                    </Link>
                </li>
            </ProtectedRoles>
        </ul>
        <div className='sidebar-footer'>
            <div className="user expand" style={{display: "none"}}>
                <p className='user-name'>{userInfo.userName}</p>
                <p className='user-role m-0'>{userInfo.role}</p>
            </div>
            <div className={`cursor-pointer logout-btn ${expandSideBar ? "mx-auto" : ""}`} onClick={logout}>
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
            </div>
        </div>
    </div>
    </>
  )
}
