import React, { useEffect, useState } from 'react'
import GridItem from '../GridItem/GridItem'
import AddBtn from '../AddBtn/AddBtn'
import CategoryForm from '../CategoryForm/CategoryForm';
import { useApi } from '../Store/ApiContext';
import axios from 'axios';
import joi from 'joi';
import { toast } from 'react-toastify';
import TopBar from '../TopBar/TopBar';
import TopBarPage from '../TopBarPage/TopBarPage';

export default function CategoryPage() {

    const [data, setData] = useState([]);
    const [oldData, setOldData] = useState({})

    const {baseApi, openFormComponent, closeFormComponent, validationData, responseApi, getData, removeData} = useApi()
    
    async function handleApiResponse(data) {
        console.log(data);
        const result = await responseApi(oldData?.categoryName ? `category/${oldData?.id}` : "category", oldData?.id ? "put" : "post", data)
        console.log(result);
        if (result?.message === "success") {
            closeFormComponent();
            fetchData()
            return 1
        } else {
            toast('لقد حدث خطأ', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                rtl : true
                });
            console.log(result);
        }
    }
    async function sendData(data) {
        const apiResponse = await handleApiResponse(data)
        console.log(apiResponse);
        if (apiResponse) {
            return 1
        }
    }
    async function fetchData() {
        const {data} = await getData("category")
        setData(data)
    };
    useEffect(() => {
        
        fetchData();
    }, [setData, getData])

    
  return (
    <>
    <section>
        <TopBarPage titlePage={"التصنيفات"} textAddBtn={"اضافة تصنيف"}/>
        <div className="container-fluid  p-0 text-start mt-5 ">
            <GridItem data={data} setOldData={setOldData} fetchData={fetchData}/>
        </div>
    </section>
    <CategoryForm sendData={sendData} setOldData={setOldData} oldData={oldData} />
    </>
  )
}
