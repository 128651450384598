import { faCircleInfo, faInfo, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import $ from 'jquery';
import { Link, useNavigate } from "react-router-dom";
import { useApi } from "../Store/ApiContext";
import moment from "moment";
import PaginationTable from "../PaginationTable/PaginationTable";

export default function ArticleTableList({data, category, media, setOldData, fetchData}) {

    const navigate = useNavigate()
    const {openFormComponent, deleteData} = useApi();

    const [numberPage, setNumberPage] = useState(1);
    const [totalItem, setTotalItem] = useState(10);
    const [pagination, setPagination] = useState({
        limit : 10,
        skip : 0
    })

    function paginate(page, size) {

        if (!page || page <= 0) {
            page = 1
        }
    
        if (!size || size <= 0) {
            size = 2
        }
        console.log(size, 1);
        const skip = (page - 1) * size
        console.log({limit: parseInt(size), skip});
        return {limit: parseInt(size), skip}
    }

    useEffect(() => {
        console.log(numberPage);
        if (data?.length && numberPage) {
            setPagination(paginate(numberPage,totalItem))
        }
    }, [data, numberPage])

  return (
    <>
    <div className="table-list">
        <ul className="responsive-table scrollbar-style">
            <li className="table-header">
                <div className="col col-1" style={{flexBasis : "40%"}} >عنوان المقاله</div>
                <div className="col col-2" style={{flexBasis : "20%"}} >تصنيف المقاله</div>
                <div className="col col-3" style={{flexBasis : "20%"}} >اصدار المقاله</div>
                <div className="col col-4" style={{flexBasis : "20%"}} >الإعدادات</div>
            </li>
            {data && data.slice(pagination.skip, pagination.skip + pagination.limit).map((ele, i) => {
                return (
                        <li className={`table-row ${i % 2 === 0  ? "table-row-1" : "table-row-2"}`} key={i}>
                            <div className="col col-1" style={{flexBasis : "40%"}} data-label="عنوان المقاله">
                                {ele.titleArticle.slice(0,40)}...
                            </div>
                            <div className="col col-2" style={{flexBasis : "20%"}} data-label="تصنيف المقاله">
                                {ele.categoryArticle}
                            </div>
                            <div className="col col-3" style={{flexBasis : "20%"}} data-label="اصدار المقاله">
                                {moment(ele.createdAt).format("YYYY-MM-DD")}
                            </div>
                            <div className="col col-4" style={{flexBasis : "20%"}} data-label="الإعدادات">
                                <ul className="settings-list">
                                    <span className="edit-btn" onClick={(e) => { navigate("/article/edit"); localStorage.setItem("data", JSON.stringify(ele));}}>
                                        <FontAwesomeIcon className="me-3" icon={faPen} />
                                    </span>
                                    <span className="delete-btn"  onClick={async () => {await deleteData(`article/${ele.id}`); await fetchData();}}>
                                        <FontAwesomeIcon className="me-3" icon={faTrash} />
                                    </span>
                                </ul>
                            </div>
                        </li>
                )
            })}
        </ul>
        <PaginationTable data={data} numberPage={numberPage} setNumberPage={setNumberPage} pagination={pagination} totalItem={totalItem}/>
    </div>
    </>
  );
}
