import React, { createContext, useContext, useEffect, useState } from 'react'
import $ from 'jquery';
import axios from 'axios';
import { toast } from 'react-toastify';
import { jwtDecode } from "jwt-decode";

export const apiContext = createContext(0)

export default function ApiProvider(props) {
  
  if (process.env.NODE_ENV === 'production') {
    var baseApi = "https://node-server-ltv.aboney.com/api/v1";
    console.warn = () => {}
    console.log = () => {}
  } else {
    var baseApi = "http://localhost:8080/api/v1";
  // var baseApi = "http://3.92.134.166:3000/api/v1";
  }


    const [token, setToken] = useState(localStorage.getItem("token"))
    
    // useEffect(() => {
    //   if (!token) {
    //     setToken(localStorage.getItem("token"))
    //   }
    // }, [])
    

  function openFormComponent() {
    $(".form-component").animate({left : "0"}, 400);
    $(".bg-overlay").removeClass("d-none");
  }
  function closeFormComponent() {
    $(".form-component").animate({left : "-450px"}, 400);
    $(".bg-overlay").addClass("d-none");
  }
  function getOption(data, options) {
    const arrayOption = []
    data?.split(",").forEach(ele => {
      const option = options.find((d) => d.value === ele )
      arrayOption.push(option)
    });
    return arrayOption;
  }
  function validationData(schema, data) {
    return  schema.validate(data, {abortEarly : false})
  }
  async function responseApi(endPoint="", method, data={}) {
    const res = await axios({
        url : `${baseApi}/${endPoint}`,
        method,
        headers : {
            'Content-Type': 'application/json', 
            "authorization" : `Bearer ${token}`,
        },
        data,
    })
    // console.log(res);
    if (res.data) {
      return res.data;
    }
  }
  async function getData(endPoint="") {
    const res = await axios({
      url : `${baseApi}/${endPoint}`,
      method : "get",
      headers : {
        'Content-Type': 'application/json', 
        "authorization" : `Bearer ${token}`,
      } ,
    })
    // console.log(res);
    if (res.data) {
      return res.data;
    }
  }
  async function removeData(endPoint="") {
    const res = await axios({
      url : `${baseApi}/${endPoint}`,
      method : "patch",
      headers : {
        'Content-Type': 'application/json', 
        "authorization" : `Bearer ${token}`,
      } ,
    })
    // console.log(res);
    if (res.data) {
      // console.log(res.data);
      if (res.data.message !== "success") {
        toast('لقد حدث خطأ', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          rtl : true
      });
      }
      return res.data;
    }
  }
  async function deleteData(endPoint="") {
    const res = await axios({
      url : `${baseApi}/${endPoint}`,
      method : "delete",
      headers : {
        'Content-Type': 'application/json', 
        "authorization" : `Bearer ${token}`,
        } ,
    })
    // console.log(res);
    if (res.data) {
      // console.log(res.data);
      if (res.data.message !== "success") {
        toast('لقد حدث خطأ', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          rtl : true
      });
      }
      return res.data;
    }
  }
  function decodedToken(token=localStorage.getItem("token")) {
    if (token) {
      const decoded = jwtDecode(token);
      // console.log(decoded);
      return decoded;
    }
   
  }

  async function UploadFile(file) {
    // get secure url from our server
    const { message, url } = await fetch(`${baseApi}/server`).then(res => res.json())
    if (message === "success") {
      // console.log(url);
  
      // post the image directly to the s3 bucket
      await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        body: file
      })
    
      const imageUrl = url.split('?')[0]
      // console.log(imageUrl)
      return imageUrl;
    }
  }

  async function deleteImage(url) {
    if (url) {
          // Split the URL by "/"
    const parts = url?.split("/");

    // Get the last part of the URL
    const imageId = parts[parts.length - 1];
    // console.log(imageId.length);
    const {message} = await responseApi(`server/${imageId}`,"delete");
    return message;
    }
  }


return  <apiContext.Provider value={{baseApi, setToken, openFormComponent, getOption, validationData, responseApi, closeFormComponent, getData, removeData, decodedToken, deleteData, UploadFile, deleteImage}}>
            {props.children}
        </apiContext.Provider>
}


export const useApi = ()=> {
    const context = useContext(apiContext);
    if (context === undefined) {
      throw new Error("useUser must be used within a UserContextProvider.");
    }
    return context;
  };