import React, { useEffect, useState } from "react";
import SaveBtn from "../SaveBtn/SaveBtn";
import { useApi } from "../Store/ApiContext";
import { Controller, useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TopBarPage from "../TopBarPage/TopBarPage";
import Select from "react-select";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

export default function FormArticle() {
  const { closeFormComponent, responseApi, UploadFile, deleteImage } = useApi();
  const {ArticleAction} = useParams();
  const navigate = useNavigate()

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(true);

  const [bodyArticle, setBodyArticle] = useState("");
  const [oldData, setOldData] = useState("");
  const [imageArticleUrl, setImageArticleUrl] = useState("");

  const dot = (color = "transparent") => ({
    borderRadius: 15,
  });
  const colourStyles = {
    control: (styles) => ({ ...styles, ...dot() }),
    input: (styles) => ({ ...styles, ...dot() }), // assuming dot() is defined somewhere
    placeholder: (styles) => ({ ...styles, ...dot("#ccc"), fontWeight: 600 }), // assuming dot() is defined somewhere
    singleValue: (styles, { data }) => ({
      ...styles,
      ...dot(data.color),
      borderColor: "#6c5dd3",
    }),
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: "cairo" }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      [],
      ["link", "image", "video"],
    ],
  };
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "align",
    "direction",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const categories = [
    { value: "ثقافة", label: "ثقافة" },
    { value: "فن", label: "فن" },
  ];

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (imageArticleUrl) {
      const newData = handleData(data);
      console.log("data", data);
      console.log("newData", newData);
      const resData = await sendData(newData);
      console.log(resData);
      if (resData) {
        navigate("/article");
        clearInputs();
      }
    }
  };

  function clearInputs() {
    reset({
      titleArticle: "",
      bodyArticle : "",
      categoryArticle: "",
      imageArticle: "",
    });
    setImageArticleUrl("");
    setOldData({});
  }
  function handleData(data) {
    const newData = {
      titleArticle: data.titleArticle,
      bodyArticle: data.bodyArticle,
      categoryArticle: data.categoryArticle.value,
      imageArticle: imageArticleUrl,
    };
    return newData;
  }

  async function handleApiResponse(data) {
    console.log(data);
    console.log(oldData);
    const result = await responseApi(
      oldData?.id ? `article/${oldData?.id}` : `article`,
      oldData?.id ? "put" : "post",
      data
    );
    console.log(result);
    if (result?.message === "success") {
      closeFormComponent();
      return 1;
    } else {
      toast("لقد حدث خطأ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        rtl: true,
      });
      console.log(result);
    }
  }

  async function sendData(data) {
    const apiResponse = await handleApiResponse(data);
    console.log(apiResponse);
    if (apiResponse) {
      return 1;
    }
  }
  function setFormInputValue(data) {
    if (data?.titleArticle) {
      console.log(data);
      setValue("titleArticle", data.titleArticle);
      setValue("bodyArticle", data.bodyArticle);
      setValue("imageArticle", imageArticleUrl);
      setValue("categoryArticle", categories?.find((c) => c.value ===  data.categoryArticle));
      setImageArticleUrl(data.imageArticle)
    }
  }

  async function getImageFile(file, setState) {
    const imageUrl = await UploadFile(file);
    if (imageUrl) {
      setState(imageUrl);
    }
  }

  async function handleImageProcess(file, imageUrl, setImageUrl) {
    if (imageUrl) {
        const message = await deleteImage(imageUrl);
        if (message === "success") {
            getImageFile(file, setImageUrl);
        }
    } else {
        getImageFile(file, setImageUrl);
    }
}

  useEffect(() => {
    if (ArticleAction === "edit" && localStorage.getItem("data")) {
      const data = JSON.parse(localStorage.getItem("data"));
      setFormInputValue(data);
      setOldData(data);
      localStorage.removeItem("data")
    } else if (ArticleAction === "edit") {
      navigate("/article");
    }
  }, []);

  useEffect(() => {
    const file = getValues("imageArticle")[0];
    if (file?.type?.startsWith("image")) {
        handleImageProcess(file, imageArticleUrl, setImageArticleUrl)
    }
}, [watch("imageArticle")]);

  return (
    <>
      <TopBarPage titlePage={"اضافة مقاله"} />
      <section className="form-page mt-5 pt-3">
        <div className="container-fluid scrollbar-style test-form text-start w-100">
          <form onSubmit={handleSubmit(onSubmit)}>
            <ul className="row gx-2 gy-0 form-section">
              <li className="col-12 mb-3 mt-1">
                <input
                  id="titleArticle"
                  className="form-control"
                  placeholder="عنوان المقاله"
                  aria-label="default input example"
                  {...register("titleArticle", {
                    required: true,
                  })}
                />
                {errors.titleArticle &&
                  errors.titleArticle.type === "required" && (
                    <div className="text-danger">This is required</div>
                  )}
              </li>
              <li className="col-12 mb-3">
                <Controller
                  name="bodyArticle"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <ReactQuill
                      id="editor-container"
                      value={bodyArticle}
                      onChange={setBodyArticle}
                      modules={modules}
                      formats={formats}
                      {...field}
                    />
                  )}
                />
                {errors.bodyArticle && errors.bodyArticle.type === "required" && (
                  <div className="text-danger">This is required</div>
                )}
              </li>
              <li className="col-12 mb-3">
                <Controller
                  name="categoryArticle"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      className="basic-single"
                      classNamePrefix="تصنيف المقاله"
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="seasonType"
                      options={categories}
                      placeholder="تصنيف المقاله"
                      styles={colourStyles}
                      {...field}
                    />
                  )}
                />
                {errors.categoryArticle &&
                  errors.categoryArticle.type === "required" && (
                    <div className="text-danger">This is required</div>
                  )}
              </li>
              <li className="col-12 mb-5 d-flex align-items-center">
                <div>
                  <h6 className="mb-2">صورة المقاله </h6>
                </div>
                <div className="text-center ms-1">
                  <input
                    type="file"
                    name="imageArticle"
                    id="imageArticle"
                    className="inputFile"
                    {...register("imageArticle", { required: imageArticleUrl ? false : true })}
                  />
                  <label className="my-2 cursor-pointer" htmlFor="imageArticle">
                    {imageArticleUrl ? (
                      <div className='preview-image'>
                        <img className='w-50' src={imageArticleUrl} alt="poster with content" />
                        <span className="size-image">600x338</span>
                      </div>
                    ) : (
                      <>
                        <div className="example-image">600x338</div>
                      </>
                    )}{" "}
                  </label>
                  {errors.imageArticle &&
                    errors.imageArticle.type === "required" && (
                      <div className="text-danger">This is required</div>
                    )}
                </div>
              </li>
            </ul>
            <div className="form-page-btn">
              <SaveBtn text={"حفظ"} />
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
