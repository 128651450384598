import React from 'react'
import { useApi } from '../Store/ApiContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export default function AddBtn({text}) {
  const {openFormComponent} = useApi();

  return (
    <button className='add-btn' onClick={openFormComponent}>
        <FontAwesomeIcon className='plus-icon' icon={faPlus} />
        {text}
    </button>
  )
}
