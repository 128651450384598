import React, { useEffect, useState } from 'react'
import EditMedia from './EpisodePage'
import AddBtn from '../AddBtn/AddBtn'
import EpisodeForm from '../EpisodeForm/EpisodeForm'
import TopBar from '../TopBar/TopBar'
import TopBarPage from '../TopBarPage/TopBarPage'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { useApi } from '../Store/ApiContext'
import EpisodeTableList from '../EpisodeTableList/EpisodeTableList'

export default function EpisodePage() {

    const {mediaSeason, media} = useParams();
    console.log(mediaSeason);
    const [data, setData] = useState([]);
    const [oldData, setOldData] = useState({})

    const {baseApi, openFormComponent, closeFormComponent, validationData, responseApi, getData, removeData} = useApi()

    async function handleApiResponse(data) {
        console.log(data);
        console.log(oldData);
        const result = await responseApi(oldData?.id ? `episode/${oldData?.id}` : `episode/${mediaSeason}`, oldData?.id ? "put" : "post", data)
        console.log(result);
        if (result?.message === "success") {
            closeFormComponent();
            fetchData()
            return 1
        } else {
            toast('لقد حدث خطأ', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                rtl : true
                });
            console.log(result);
        }
    }

    async function sendData(data) {
        const apiResponse = await handleApiResponse(data)
        console.log(apiResponse);
        if (apiResponse) {
            return 1
        }
    }
    async function fetchData() {
        const {data} = await getData(`episode/${mediaSeason}`)
        console.log(data);
        setData(data)
    };

    useEffect(() => {
        console.log(media);
        fetchData();
    }, [setData, getData])

  return (
    <>
       <section>
       <TopBarPage titlePage={"الحلقات"} textAddBtn={"اضافة حلقة"}/>
        <div className="media-list container-fluid text-start mt-5">
            {/* <div className='header-media-list d-flex justify-content-between align-items-center mb-5'>
                <div>
                    <h2 className="fw-bolder title-page">
                        الحلقات
                    </h2>
                </div>
                <div>
                    <AddBtn text={"اضافة حلقة"}/>
                </div>
            </div> */}
            <EpisodeTableList data={data} setOldData={setOldData} fetchData={fetchData} />
        </div>
    </section>
    <EpisodeForm sendData={sendData} setOldData={setOldData} oldData={oldData} />
    </>
  )
}
